import { storage as StorageI18n } from "./storage";
import { storageAdd } from "./storage-add";
import { storageList } from "./storage-list";
import { storageTransfer } from "./storage-transfer";
import { storageHistory } from "./storage-history";
import { storageAlerts } from "./storage-alerts";
import { nfReport } from "./nf-report";
import { cmvCost } from "./cmv-cost";
import { cmvReport } from "./cmv-report";
import { lossReport } from "./loss-report";
import { purchaseInvoice } from "./purchase-invoice";
import { inventoryControl } from "./inventory-control";
import { storages } from "./storages";
import { abcReports } from "./abc-reports";
import { irregularCmvProducts } from "./irregular-cmv-products";
import { stockPositionReport } from "./stock-position-report";
import { profitMarginReport } from "./profit-margin-report";
import { manualInvoiceImporting } from "./manual-invoice-importing";

export const storage = {
	storage: StorageI18n,
	total: "total",
	storageAdd,
	storageList,
	storageTransfer,
	tabs: {
		profitMarginReport,
		storages,
		storageHistory,
		storageAlerts,
		nfReport,
		purchaseInvoice,
		cmvCost,
		cmvReport,
		lossReport,
		inventoryControl,
		abcReports,
		irregularCmvProducts,
		stockPositionReport,
		manualInvoiceImporting,
	},
};
