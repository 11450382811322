import * as React from "react";
import { observer } from "mobx-react-lite";

interface DeliveryLayoutProps {
	params: {
		placeId: string;
	};
}

function DeliveryLayout({ children }: React.PropsWithChildren<DeliveryLayoutProps>) {
	return <>{children}</>;
}

export default observer(DeliveryLayout);
