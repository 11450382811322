import { deliveryStoreApi } from "#api/delivery-client";
import enterprise from "#api/enterprise-client";
import { CoalaTempToken } from "#resources/api/enterprise-generated";
import { BaseStoreAuth } from "#resources/api/delivery-generated";
import { showErrorNotification } from "../notifications";

type onPLaceIdChangedType = (newPlaceId: string) => void;

const MAX_RETRY_COUNT = 3;

export class CoalaTokenManager {
	public tempToken: CoalaTempToken | null = null;
	private tokenRenewInveral = 5 * 60 * 1000; // milisecconds
	private verificationInterval = 10 * 1000;
	private isRenewActive: boolean;
	private intervalId: number | null = null;
	private lastRenew: number | null = null;
	private placeId: string | null = null;
	private onPlaceIdChanged: onPLaceIdChangedType | null = null;
	private retryCount: number = 0;

	constructor(activateOnBegin: boolean, onPlaceIdChanged?: onPLaceIdChangedType) {
		this.isRenewActive = activateOnBegin;
		if (this.isRenewActive) this.startInterval();
		// coalaAuth.errorHook = this.sdkgenErrorHook;
		deliveryStoreApi.errorHook = this.sdkgenErrorHook;
		if (onPlaceIdChanged) this.onPlaceIdChanged = onPlaceIdChanged;
	}

	public callWithAuth = <T>(call: (auth: BaseStoreAuth) => Promise<T>): Promise<T> => {
		if (this.tempToken === null) {
			// make auth request
			return new Promise((resolve, reject) => {
				this.renewFunction()
					.then(tempToken => {
						call({
							authToken: tempToken.token,
							storeId: tempToken.storeId,
						})
							.then(resolve)
							.catch(reject);
					})
					.catch(err => {
						console.error("coala-token-manager: callWithAuth");
						console.error(err);
					});
			});
		} else {
			return call({
				authToken: this.tempToken.token,
				storeId: this.tempToken.storeId,
			});
		}
	};

	public setPlaceId = (id: string) => {
		if (this.placeId !== id && this.placeId !== null && this.onPlaceIdChanged) {
			this.onPlaceIdChanged(id);
		}
		this.placeId = id;
	};

	private verifyNecessityOfRenew = () => {
		if (this.retryCount >= MAX_RETRY_COUNT) {
			this.deactivateTokenRenew();
		}

		if (this.lastRenew === null) {
			if (this.isRenewActive) this.renewFunction();
		} else {
			const timeoff = new Date().getTime() - this.lastRenew;
			if (timeoff > this.tokenRenewInveral) {
				this.renewFunction();
			}
		}
	};

	private renewFunction = () =>
		new Promise<CoalaTempToken>((resolve, reject) => {
			if (!this.placeId) {
				reject("coala-token-manager: does not have placeId");
				return;
			}

			enterprise
				.getCoalaTempToken({
					placeId: this.placeId,
				})
				.then(temporaryToken => {
					this.tempToken = temporaryToken;
					this.lastRenew = new Date().getTime();
					resolve(this.tempToken);
				})
				.catch(err => {
					this.retryCount++;
					console.error("coala-token-manager: " + err.message);
					console.error("coala-token-manager: error on fetch\n," + err.message);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				});
		});

	private startInterval = () => {
		this.verifyNecessityOfRenew();
		this.intervalId = window.setInterval(
			this.verifyNecessityOfRenew,
			this.verificationInterval,
		);
	};

	private stopInterval = () => {
		if (this.intervalId) window.clearInterval(this.intervalId);
		else {
			console.error("coala-token-manager: trying to clear interval wihtout intervalId");
		}
	};

	public activateTokenRenew = () => {
		if (this.isRenewActive) {
			console.error(
				"coala-token-manager: trying to activate renew, but renew is already activated",
			);
		} else {
			this.isRenewActive = true;
			this.startInterval();
		}
	};

	public deactivateTokenRenew = () => {
		if (!this.isRenewActive) {
			console.error(
				"coala-token-manager: trying to deactivate renew, but renew is already deactivated",
			);
		} else {
			this.isRenewActive = false;
			this.stopInterval();
		}
	};

	private sdkgenErrorHook = (result: any) => {
		if (result && result.type && result.type === "ExpiredToken") this.renewFunction();
	};

	public clearToken = () => {
		this.tempToken = null;
	};

	public clearPlaceId = () => {
		this.placeId = null;
	};
}
