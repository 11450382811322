import { expiredSession } from "./expired-session";

export const noAuthPT = {
	expiredSession,
	login: {
		dashboard: "Dashboard",
	},
	errors: {
		required: "Campo obrigatório",
		passwordMustBeSame: "As senhas precisam ser iguais",
		minLength: "A senha deve ter no mínimo {{count}} caracteres",
	},
	actions: {
		send: "Enviar",
	},
	confirmMessages: {
		passwordUpdated: "Senha atualizada com sucesso",
	},
	labels: {
		password: "Senha",
		confirmPassword: "Confirmação de senha",
	},
	recoveryPassword: "Recuperação de senha",
};
