import * as React from "react";

import { asyncComponent as AC } from "#helpers/async-component";
// SYNCRONOUS PAGES
import { sendPageview } from "#helpers/pageview";
import Login from "#pages/no-auth/login";
import Main from "#structure/main";

import { PrechargeOperationRoutes } from "#pages/place/precharge/types";
import { debounce } from "lodash-es";
import { IndexRoute, Route, Router } from "react-router";
import { history, requireAuth, requireNotAuth, updatePlaceAndEvent } from "./helpers";

const App = require("#structure/app").default;

const RecoveryPassword = AC(() =>
	import("#pages/no-auth/recovery-password").then(module => module.RecoveryPassword),
);

// ORGANIZATION SCOPE
const OrgFiscalData = AC(() =>
	import("#pages/org/fiscal-data").then(module => module.FiscalData),
);
const OrgFiscalProfileFormPage = AC(() =>
	import("#pages/org/fiscal-profile-form").then(module => module.FiscalProfileFormPage),
);
const OrgFiscalGroupFormPage = AC(() =>
	import("#pages/org/fiscal-group-form").then(module => module.FiscalGroupFormPage),
);
const OrgFiscalCouvertPage = AC(() =>
	import("#pages/org/fiscal-couvert-form").then(module => module.FiscalCouvertFormPage),
);
const TeamEmployees = AC(() =>
	import("#pages/team/team").then(module => module.TeamEmployeesContainer),
);

const OrgEmployeesRoles = AC(() =>
	import("#pages/org/team-and-roles/employees-role").then(
		module => module.EmployeesRoles,
	),
);
const OrgProducts = AC(() =>
	import("#pages/org/products").then(module => module.OrgProducts),
);

const OrgCategories = AC(() =>
	import("#pages/org/categories").then(module => module.Categories),
);
const OrgReport = AC(() => import("#pages/org/report").then(module => module.Report));

const OrgCommunication = AC(() =>
	import("#pages/org/communication").then(module => module.Communication),
);

const OrgChartOfAccounts = AC(() =>
	import("#pages/org/chart-of-accounts").then(module => module.ChartOfAccountsPage),
);

const OrgVendors = AC(() =>
	import("#pages/org/vendors").then(module => module.VendorsPage),
);
const Clients = AC(() => import("#pages/org/clients").then(module => module.ClientsPage));

// RECHARGE MANAGEMENT
const RechargeManagement = AC(() =>
	import("#components/recharge-management-report").then(
		module => module.RechargeManagementPage,
	),
);

// COMUNICATION SCOPE
const Packages = AC(() =>
	import("#pages/org/communication/packages").then(module => module.Packages),
);
const Message = AC(() =>
	import("#pages/org/communication/message").then(module => module.Message),
);
const CrudMessage = AC(() =>
	import("#pages/org/communication/message/crud-message").then(
		module => module.CrudMessage,
	),
);
const WhatsAppTemplate = AC(() =>
	import("#pages/org/communication/message/crud-message/whatsapp/new-template").then(
		module => module.NewTemplatePage,
	),
);

const OrgClientArea = AC(() =>
	import("#pages/org/client-area").then(module => module.ClientArea),
);

const OrgClientAreaDetail = AC(() =>
	import("#pages/org/client-area/detail").then(module => module.ClientAreaDetail),
);

const OrgSatisfactionSurvey = AC(() =>
	import("#pages/org/satisfaction-survey").then(module => module.SatisfactionSurvey),
);
const OrgLoyalty = AC(() => import("#pages/org/loyalty").then(module => module.Loyalty));

const LoyaltyProgram = AC(() =>
	import("#pages/org/loyalty/programs/create-steps/create-program-container").then(
		module => module.default,
	),
);

const LoyaltyCommingSoon = AC(() =>
	import("#pages/org/loyalty/comming-soon/comming-soon").then(
		module => module.LoyaltyCommingSoon,
	),
);

const LoyaltyProgramDetails = AC(() =>
	import("#pages/org/loyalty/programs/program-details/program-details").then(
		module => module.ProgramDetailsPage,
	),
);

const Giftback = AC(() => import("#pages/org/giftback").then(module => module.Giftback));
const GiftbackList = AC(() =>
	import("#pages/org/giftback/giftback-list").then(module => module.GiftbackList),
);
const GiftbackReport = AC(() =>
	import("#pages/org/giftback/giftback-report").then(module => module.GiftbackReport),
);
const GiftbackPowerBI = AC(() =>
	import("#pages/org/giftback/giftback-power-bi").then(module => module.GiftbackPowerBI),
);

//Gift Card
// const GiftCard = AC(() => import("#pages/org/gift-card").then(module => module.GiftCard));
// const GiftCardMetabase = AC(() =>
// 	import("#pages/org/gift-card/metabase").then(module => module.default),
// );

const Places = AC(() => import("#pages/org/places").then(module => module.default));

// PLACE SCOPE
const Events = AC(() => import("#pages/place/events").then(module => module.NewEvents));

const Conference = AC(() =>
	import("#pages/event/conference").then(module => module.Conference),
);
const Promotions = AC(() =>
	import("#pages/place/promotion").then(module => module.default),
);
const DiscountCategory = AC(() =>
	import("#pages/place/discount-category/discount-category").then(
		module => module.DiscountCategory,
	),
);
const BonusCategories = AC(() =>
	import("#pages/place/bonus-categories").then(module => module.BonusCategories),
);
const PrechargeReport = AC(() =>
	import("#pages/place/precharge-report").then(module => module.PreChargeReport),
);
const GrabAndGo = AC(() =>
	import("#pages/place/grab-and-go/grab-and-go.presenter").then(
		module => module.grabAndGoPage,
	),
);
const MetabaseReports = AC(() =>
	import("#pages/place/metabase").then(module => module.default),
);

const MultivendorDebtorsReport = AC(() =>
	import("#pages/place/multivendor-debtors-report/debtors-report.view").then(
		module => module.DebtorsReport,
	),
);
const MultivendorProductsSold = AC(() =>
	import("#pages/place/multivendor-products-sold/products-sold.presenter").then(
		module => module.MultivendorProductsSold,
	),
);
const MultivendorClosings = AC(() =>
	import("#pages/place/multivendor-closings/closings.presenter").then(
		module => module.MultivendorClosings,
	),
);
const MultivendorExtractWrapper = AC(() =>
	import("#pages/place/multivendor-extract/multivendor-extract-wrapper").then(
		module => module.MultivendorExtractWrapper,
	),
);
const MultivendorRevenues = AC(() =>
	import("#pages/place/multivendor-revenues/revenues.presenter").then(
		module => module.MultivendorRevenues,
	),
);
const CreatePromotionV2 = AC(() =>
	import("#pages/place/promotion-v2").then(module => module.default),
);
const BusinessIntelligence = AC(() =>
	import("#pages/place/business-intelligence/").then(module => module.default),
);
const Bars = AC(() => import("#pages/place/bars/bars").then(module => module.default));
const Products = AC(() => import("#pages/place/products").then(module => module.default));
const Storage = AC(() =>
	import("#pages/place/storage/storage").then(module => module.default),
);
const StorageAdd = AC(() =>
	import("#pages/place/storage-add/storage-add").then(module => module.default),
);
const StorageTransfer = AC(() =>
	import("#pages/place/storage-transfer/storage-transfer").then(module => module.default),
);
const StorageList = AC(() =>
	import("#pages/place/storage-list/storage-list").then(module => module.default),
);
const ABCSupplierReport = AC(() =>
	import("#pages/place/abc-reports/abc-supplier-report/abc-supplier-report").then(
		module => module.default,
	),
);
const ABCPurchaseReport = AC(() =>
	import("#pages/place/abc-reports/abc-purchase-report/abc-purchase-report").then(
		module => module.default,
	),
);
const ProductArea = AC(() =>
	import("#pages/place/products/product-area").then(module => module.ProductArea),
);
const ManageMenu = AC(() =>
	import("#pages/place/product-menu/manage-menu").then(module => module.ManageMenu),
);
const MenuArea = AC(() =>
	import("#pages/place/product-menu/menu-area").then(module => module.MenuArea),
);
const Combos = AC(() => import("#pages/place/combos").then(module => module.default));
const BeerDispenser = AC(() =>
	import("#pages/place/beer-dispenser").then(module => module.default),
);
const PersonalizationSettings = AC(() =>
	import("#pages/place/personalization-settings/index").then(module => module.default),
);
const InventoryControl = AC(() =>
	import("#pages/place/inventory-control").then(module => module.InventoryControl),
);
const InventoryControlStepper = AC(() =>
	import("#pages/place/inventory-control/stepper-inventory-control").then(
		module => module.StepperInventoryControl,
	),
);
const StockPositionReport = AC(() =>
	import("#pages/place/stock-position-report/stock-position-report").then(
		module => module.StockPositionReportPage,
	),
);
const CMV = AC(() => import("#pages/place/cmv").then(module => module.CMV));
const Supplies = AC(() =>
	import("#pages/place/supplies").then(module => module.Supplies),
);
const Entrance = AC(() =>
	import("#pages/place/entrance").then(module => module.Entrance),
);
const EntranceForm = AC(() =>
	import("#pages/place/entrance/entrance-form").then(module => module.EntranceForm),
);
const GuestLists = AC(() =>
	import("#pages/place/guest-lists").then(module => module.default),
);
const GuestListsPromoter = AC(() =>
	import("#pages/place/guests-list-promoter/promoter-wrapper").then(
		module => module.default,
	),
);
const GuestListsReport = AC(() =>
	import("#pages/place/guests-list-report/report-container").then(
		module => module.default,
	),
);
const PlaceAnalyticPayments = AC(() =>
	import("#pages/place/analytic-payments").then(module => module.default),
);
const PlaceClientDetail = AC(() =>
	import("#pages/place/client-detail").then(module => module.default),
);
const PlaceSettings = AC(() =>
	import("#pages/place/settings").then(module => module.default),
);
const StorageHistory = AC(() =>
	import("#pages/place/storage-history").then(module => module.default),
);
const StorageAlerts = AC(() =>
	import("#pages/place/storage-alerts").then(module => module.default),
);
const Kardex = AC(() => import("#pages/place/kardex").then(module => module.default));
const NfReport = AC(() =>
	import("#pages/place/nf-report").then(module => module.default),
);

const PurchaseInvoice = AC(() =>
	import("#pages/place/purchase-invoice").then(module => module.PurchaseInvoice),
);

const ManualInvoiceImporting = AC(() =>
	import("#pages/place/purchase-invoice/manual-invoice-importing").then(
		module => module.ManualInvoiceImporting,
	),
);

const ImportInvoice = AC(() =>
	import("#pages/place/purchase-invoice/import-invoice/import-invoice").then(
		module => module.ImportPage,
	),
);

const PurchaseInvoiceSefazImport = AC(() =>
	import("#pages/place/purchase-invoice/import-sefaz-invoice/import-sefaz-invoice").then(
		module => module.ImportSefazInvoice,
	),
);

const ProfitMarginReport = AC(() =>
	import("#pages/place/profit-margin-report").then(module => module.default),
);
const CMVCost = AC(() => import("#pages/place/cmv-cost").then(module => module.default));
const CMVReport = AC(() =>
	import("#pages/place/cmv-report").then(module => module.CMVReport),
);
const IrregularCMVProducts = AC(() =>
	import("#pages/place/irregular-cmv-products").then(
		module => module.IrregularCMVProducts,
	),
);
const ProductionControl = AC(() =>
	import("#pages/place/production-control").then(module => module.ProductionControl),
);
const RecipeForm = AC(() =>
	import("#pages/place/recipe-form").then(module => module.RecipeForm),
);
const Recipe = AC(() => import("#pages/place/recipe").then(module => module.Recipe));
const ProductionControlProduction = AC(() =>
	import("#pages/place/production-control-production").then(
		module => module.ProductionControlProduction,
	),
);
const ProductionControlViewProduction = AC(() =>
	import("#pages/place/production-control-view-production").then(
		module => module.ViewProduction,
	),
);
const LossReport = AC(() =>
	import("#pages/place/loss-report").then(module => module.LossReport),
);
const TransfersReport = AC(() =>
	import("#pages/place/transfers-report").then(module => module.TransfersReport),
);
const Extract = AC(() =>
	import("#pages/place/extract").then(module => module.ExtractPage),
);
const FiscalReport = AC(() =>
	import("#pages/place/fiscal-report").then(module => module.default),
);
const EmissionErrors = AC(() =>
	import("#pages/place/emission-errors").then(module => module.EmissionErrorsPage),
);
const Invoices = AC(() => import("#pages/place/invoices").then(module => module.default));
const MissingInvoices = AC(() =>
	import("#pages/place/missing-invoices").then(module => module.default),
);
const Transactions = AC(() =>
	import("#pages/place/transactions").then(module => module.default),
);
const Recharges = AC(() =>
	import("#pages/place/recharges-fiscal").then(module => module.default),
);
const Emited = AC(() => import("#pages/place/emited").then(module => module.default));
const Search = AC(() => import("#pages/place/search").then(module => module.default));
const NotEmitedProducts = AC(() =>
	import("#pages/place/not-emited-products").then(module => module.default),
);
const POS = AC(() => import("#pages/place/pos").then(module => module.default));
const ZigConta = AC(() =>
	import("#pages/place/zig-conta").then(module => module.default),
);
const FinancialReports = AC(() =>
	import("#pages/place/financial-reports").then(module => module.default),
);
const BarReports = AC(() =>
	import("#pages/place/bar-reports").then(module => module.BarReports),
);
const TableReports = AC(() =>
	import("#pages/place/table-reports").then(module => module.TableReportsPage),
);
const EntraceReports = AC(() =>
	import("#pages/place/entrace-reports").then(module => module.EntraceReports),
);
const Audits = AC(() => import("#pages/place/audits").then(module => module.default));
const WhatsUp = AC(() =>
	import("#pages/place/whats-up/whats-up-container").then(module => module.default),
);
const PrechargeContainer = AC(() =>
	import("#pages/place/precharge/precharge").then(
		module => module.PrechargeOperationPageContainer,
	),
);
const PasswordCard = AC(() =>
	import("#pages/place/password-card").then(module => module.PasswordCard),
);
const Reserve = AC(() =>
	import("#pages/place/reserve/reserve").then(module => module.default),
);
const ReserveManager = AC(() =>
	import("#pages/place/reserve/tab-reserve-manager/reserve-manager").then(
		module => module.default,
	),
);
const QueueManager = AC(() =>
	import("#pages/place/reserve/tab-queue-manager/queue-manager").then(
		module => module.default,
	),
);
const GeneralReserveConfig = AC(() =>
	import("#pages/place/reserve/tab-general-config/general-config").then(
		module => module.default,
	),
);
const CalendarManagement = AC(() =>
	import("#pages/place/reserve/tab-calendar-management/calendar-management").then(
		module => module.default,
	),
);

const Contracts = AC(() =>
	import("#pages/place/contracts").then(module => module.Contracts),
);

const ContractsHistoryDetails = AC(() =>
	import("#pages/place/contracts/contracts-history/contracts-history-details").then(
		module => module.ContractsHistoryDetails,
	),
);

const TransactionsReportPlace = AC(() =>
	import("#pages/place/transactions-report").then(
		module => module.TransactionsReportPlace,
	),
);

// const RechargeManagement = AC(() =>
// 	import("#pages/place/recharge-management").then(module => module.RechargeManagement),
// );

// COALA DELIVERY
const CoalaMenus = AC(() =>
	import("#pages/place/coala-menus").then(module => module.default),
);
const CoalaProducts = AC(() =>
	import("#pages/place/coala-products").then(module => module.default),
);
const CoalaVendorReport = AC(() =>
	import("#pages/place/coala-vendor-report/report.presenter").then(
		module => module.default,
	),
);
const CoalaOrderReport = AC(() =>
	import("#pages/place/coala-order-report/report.presenter").then(
		module => module.default,
	),
);
const CoalaProductReport = AC(() =>
	import("#pages/place/coala-product-report/report.presenter").then(
		module => module.default,
	),
);
const CoalaEditmenu = AC(() =>
	import("#pages/place/coala-edit-menu").then(module => module.default),
);
const ImportXml = AC(() =>
	import("#pages/place/import-xml/import-xml").then(module => module.default),
);

// DELIVERY
import DeliveryLayout from "#pages/place/delivery/layout";

const DeliverySettings = AC(() =>
	import("#pages/place/delivery/settings/index").then(module => module.default),
);

// EVENT SCOPE
const EventPanel = AC(() =>
	import("#pages/event/event-panel").then(module => module.default),
);
const DeviceWrapper = AC(() =>
	import("#pages/event/device-wrapper").then(module => module.default),
);
const SellChip = AC(() =>
	import("#pages/event/sell-chip").then(module => module.default),
);
const EventReports = AC(() =>
	import("#pages/event/event-reports").then(module => module.default),
);
const EventReserve = AC(() =>
	import("#pages/event/event-reserve").then(module => module.default),
);
const EventReserveReport = AC(() =>
	import("#pages/event/event-reserve-report").then(module => module.default),
);
const TagGroups = AC(() =>
	import("#pages/place/tag-groups").then(module => module.default),
);
const MultivendorProductsSoldEvent = AC(() =>
	import("#pages/event/multivendor/products-sold/products-sold.presenter").then(
		module => module.MultivendorProductsSold,
	),
);
const MultivendorClosingsEvent = AC(() =>
	import("#pages/event/multivendor/closings/closings.presenter").then(
		module => module.MultivendorClosings,
	),
);
const MultivendorRevenuesEvent = AC(() =>
	import("#pages/event/multivendor/revenues/revenues.presenter").then(
		module => module.MultivendorRevenues,
	),
);
const EventBarReports = AC(() =>
	import("#pages/event/event-bar-reports").then(module => module.EventBarReports),
);
const EventTableReports = AC(() =>
	import("#pages/event/table-reports").then(module => module.TableReportsPage),
);
const EventEntranceReports = AC(() =>
	import("#pages/event/event-entrance-reports").then(module => module.EntranceReports),
);
const EventClientDetail = AC(() =>
	import("#pages/event/event-client-detail").then(module => module.EventClientDetail),
);
const EventClients = AC(() =>
	import("#pages/event/event-clients").then(module => module.EventClients),
);
const EventAnalyticPayments = AC(() =>
	import("#pages/event/event-reports/analytic-payments").then(module => module.default),
);
const EventZigTags = AC(() =>
	import("#pages/event/zig-tags").then(module => module.default),
);
const OperationOrangeScreenReport = AC(() =>
	import(
		"#pages/event/event-reports/operations-report/orange-screen-report/orange-screen-report"
	).then(m => m.OrangeScreenReportPage),
);

const TransactionsReportEvent = AC(() =>
	import(
		"#pages/event/event-reports/operations-report/transactions-report/transactions-report"
	).then(module => module.TransactionsReportEvent),
);

const OperationTotemActionsReport = AC(() =>
	import("#pages/event/event-reports/operations-report/totem-actions-report").then(
		m => m.TotemActionsReportPage,
	),
);
const HawkersReportPage = AC(() =>
	import("#pages/event/hawkers-report").then(module => module.HawkersReportPage),
);
// const RechargeManagementInEvent = AC(() =>
// 	import("#pages/event/recharge-management").then(
// 		module => module.RechargeManagementInEvent,
// 	),
// );

// BACKOFFICE
const AccountsPayableReceivable = AC(() =>
	import("#pages/backoffice/accounts-payable-receivable").then(
		module => module.AccountsPayableReceivable,
	),
);
const FormAccountsPayableReceivable = AC(() =>
	import(
		"#pages/backoffice/accounts-payable-receivable/components/form-accounts-payable"
	).then(module => module.FormAccountsPayableReceivable),
);

const BillType = AC(() =>
	import("#pages/backoffice/bill-type").then(module => module.default),
);
// warning: código comentado até que seja liberado o desenvolvimento
// const Reports = AC(() =>
// 	import("#pages/backoffice/reports").then(module => module.default),
// );
const CostCenter = AC(() =>
	import("#pages/backoffice/cost-center").then(module => module.default),
);
const BankAccounts = AC(() =>
	import("#pages/backoffice/bank-accounts/bank-accounts").then(
		module => module.BankAccounts,
	),
);
const Cashflow = AC(() =>
	import("#pages/backoffice/cash-flow").then(module => module.CashFlow),
);
const EventConference = AC(() =>
	import("#pages/backoffice/event-conference").then(module => module.EventConference),
);
const Suppliers = AC(() =>
	import("#pages/backoffice/suppliers").then(module => module.SuppliersPage),
);
const ConciliationPage = AC(() =>
	import("#pages/backoffice/conciliation").then(module => module.ConciliationPage),
);
//warning: código comentado até que seja liberado o desenvolvimento
// const PlanOfAccounts = AC(() =>
// 	import("#pages/backoffice/plan-of-accounts").then(module => module.PlanOfAccounts),
// );

const FinExManagerialReportPage = AC(() =>
	import("#pages/backoffice/dre").then(module => module.FinExManagerialReportPage),
);

// OTHERS
const NoMatch = AC(() => import("#components/no-match").then(module => module.default));

const MdsDemo = AC(() =>
	import("#pages/org/mds-demo/mds-demo").then(module => module.PageMdsDemo),
);

const debouncedUpdatePlaceAndEvent = debounce(updatePlaceAndEvent, 50);

export const privateRoutes = (
	<Route
		onEnter={requireAuth}
		path="/"
		component={App}
		onChange={debouncedUpdatePlaceAndEvent}
	>
		<Route component={Main}>
			<IndexRoute components={{ main: Places }} />
			<Route path="vendors" components={{ main: OrgVendors }} />
			<Route path="fiscal-data" components={{ main: OrgFiscalData }} />
			<Route
				path="fiscal-profile-form(/:fiscalProfileId)"
				components={{ main: OrgFiscalProfileFormPage }}
			/>
			<Route
				path="fiscal-group-form(/:fiscalProductGroupId)"
				components={{ main: OrgFiscalGroupFormPage }}
			/>
			<Route
				path="fiscal-couvert-form(/:fiscalCouvertPlaceId)"
				components={{ main: OrgFiscalCouvertPage }}
			/>
			<Route path="employees" components={{ main: TeamEmployees }} />
			<Route path="clients" components={{ main: Clients }} />
			<Route path="mds-demo" components={{ main: MdsDemo }} />
			<Route path="employees-roles" components={{ main: OrgEmployeesRoles }} />
			<Route path="products" components={{ main: OrgProducts }} />
			<Route path="categories" components={{ main: OrgCategories }} />
			<Route path="reports" components={{ main: OrgReport }} />
			<Route path="chart-of-accounts" components={{ main: OrgChartOfAccounts }} />
			<Route path="communication">
				<IndexRoute components={{ main: OrgCommunication }} />
				<Route path="packages" components={{ main: Packages }} />
				<Route path="message" components={{ main: Message }} />
				<Route path="message/create" components={{ main: CrudMessage }} />
				<Route path="message/:messageId" components={{ main: CrudMessage }} />
				<Route path="message/resume/:messageId" components={{ main: CrudMessage }} />
				<Route path="message/create/template" components={{ main: WhatsAppTemplate }} />
			</Route>
			{/* so wrong ... */}
			<Route path="client-area">
				<IndexRoute components={{ main: OrgClientArea }} />
				<Route
					path="detail(/:workspaceId/:reportId)"
					components={{ main: OrgClientAreaDetail }}
				/>
			</Route>

			<Route path="loyalty">
				<IndexRoute components={{ main: OrgLoyalty }} />

				<Route path="comming-soon" components={{ main: LoyaltyCommingSoon }} />
				<Route path="program/new(/:programId)" components={{ main: LoyaltyProgram }} />
				<Route path="program(/:programId)" components={{ main: LoyaltyProgramDetails }} />
			</Route>
			<Route path="giftback">
				<IndexRoute components={{ main: Giftback }} />
				<Route path="management" components={{ main: Giftback }} />
				<Route path="list" components={{ main: GiftbackList }} />
				<Route path="report" components={{ main: GiftbackReport }} />
				<Route path="power-bi" components={{ main: GiftbackPowerBI }} />
			</Route>

			{/* <Route path="gift-card" components={{ main: GiftCard }} />
			<Route path="gift-card/metabase" components={{ main: GiftCardMetabase }} /> */}

			{/* <Route
						path="product-feature-templates"
						components={{ main: OrgProductFeatureTemplates }}
					/> */}
			<Route path="/satisfaction-survey">
				<IndexRoute components={{ main: OrgSatisfactionSurvey }} />
			</Route>
			<Route path="place/:placeId">
				<IndexRoute components={{ main: Events }} />
				<Route path="clients" components={{ main: Clients }} />
				<Route path="tag-groups" components={{ main: TagGroups }} />
				<Route path="promotions" components={{ main: Promotions }} />
				<Route path="discount-categories" components={{ main: DiscountCategory }} />
				<Route path="employees" components={{ main: TeamEmployees }} />
				<Route path="bonus-categories" components={{ main: BonusCategories }} />
				{Object.values(PrechargeOperationRoutes).map(path => (
					<Route key={path} path={path} components={{ main: PrechargeContainer }} />
				))}
				<Route path="precharge-report" components={{ main: PrechargeReport }} />
				<Route path="grab-and-go" components={{ main: GrabAndGo }} />
				<Route path="transfer-report" components={{ main: MetabaseReports }} />
				<Route
					path="multivendor-products-sold"
					components={{ main: MultivendorProductsSold }}
				/>
				<Route
					path="multivendor-debtors-report"
					components={{ main: MultivendorDebtorsReport }}
				/>
				<Route
					path="multivendor-extract"
					components={{ main: MultivendorExtractWrapper }}
				/>
				<Route path="multivendor-closings" components={{ main: MultivendorClosings }} />
				<Route path="multivendor-revenues" components={{ main: MultivendorRevenues }} />
				<Route
					path="create-promotion-v2(/:promotionId)"
					components={{ main: CreatePromotionV2 }}
				/>
				<Route path="business-intelligence" components={{ main: BusinessIntelligence }} />
				<Route path="bars" components={{ main: Bars }} />
				<Route path="products" components={{ main: Products }} />
				<Route path="storage/:storageId" components={{ main: Storage }} />
				<Route path="storage-add/:storageId" components={{ main: StorageAdd }} />
				<Route
					path="storage-transfer/:storageId"
					components={{ main: StorageTransfer }}
				/>
				<Route path="storage-list" components={{ main: StorageList }} />
				<Route path="abc-supplier-report" components={{ main: ABCSupplierReport }} />
				<Route path="abc-purchase-report" components={{ main: ABCPurchaseReport }} />
				<Route path="products">
					<IndexRoute components={{ main: Products }} />
					<Route path=":productId/product-area" components={{ main: ProductArea }} />
				</Route>
				<Route path="manage-menu">
					<IndexRoute components={{ main: ManageMenu }} />
					<Route path=":menuId/menu-area" components={{ main: MenuArea }} />
				</Route>
				<Route path="inventory-control">
					<IndexRoute components={{ main: InventoryControl }} />
					<Route
						path="stepper/:step(/:inventoryId)"
						components={{ main: InventoryControlStepper }}
					/>
				</Route>
				<Route path="combos" components={{ main: Combos }} />
				<Route path="contracts">
					<IndexRoute components={{ main: Contracts }} />
					<Route path=":contractId" components={{ main: ContractsHistoryDetails }} />
				</Route>
				<Route path="beer-dispenser" components={{ main: BeerDispenser }} />
				<Route
					path="personalization-settings"
					components={{ main: PersonalizationSettings }}
				/>
				<Route path="CMV" components={{ main: CMV }} />
				<Route path="stock-position-report" components={{ main: StockPositionReport }} />
				<Route path="supplies" components={{ main: Supplies }} />
				<Route path="entrance">
					<IndexRoute components={{ main: Entrance }} />
					<Route path="form(/:entranceId)" components={{ main: EntranceForm }} />
				</Route>
				<Route path="guest-lists" components={{ main: GuestLists }} />
				<Route path="reserve" components={{ main: Reserve }} />
				<Route path="reserve/reserve-manager" components={{ main: ReserveManager }} />
				<Route path="reserve/queue-manager" components={{ main: QueueManager }} />
				<Route
					path="reserve/calendar-management"
					components={{ main: CalendarManagement }}
				/>
				<Route
					path="reserve/general-config"
					components={{ main: GeneralReserveConfig }}
				/>
				<Route path="guest-lists-promoter" components={{ main: GuestListsPromoter }} />
				<Route path="guest-lists-report" components={{ main: GuestListsReport }} />
				<Route path="analytic-payments" components={{ main: PlaceAnalyticPayments }} />
				<Route
					path="client-detail/:clientId/:since/:until"
					components={{ main: PlaceClientDetail }}
				/>
				<Route path="settings" components={{ main: PlaceSettings }} />
				<Route path="storage-history" components={{ main: StorageHistory }} />
				<Route path="storage-alerts" components={{ main: StorageAlerts }} />
				<Route
					path="kardex(/:storageId)(/:productId)(/:since)(/:until)(/:dateFilterBy)"
					components={{ main: Kardex }}
				/>
				<Route path="nf-report" components={{ main: NfReport }} />

				<Route path="purchase-invoice">
					<IndexRoute components={{ main: PurchaseInvoice }} />
					<Route path="import-sefaz" components={{ main: PurchaseInvoiceSefazImport }} />
					<Route path="import/:importedId" components={{ main: ImportInvoice }} />
					<Route path="manual" components={{ main: ManualInvoiceImporting }} />
				</Route>

				<Route path="profit-margin-report" components={{ main: ProfitMarginReport }} />
				<Route path="cmv-cost" components={{ main: CMVCost }} />
				<Route
					path="cmv-report/irregular-products/:since/:until"
					components={{ main: IrregularCMVProducts }}
				/>
				<Route path="production-control">
					<IndexRoute components={{ main: ProductionControl }} />
					<Route path="recipe">
						<Route path="new" components={{ main: RecipeForm }} />
						<Route path=":recipeId/edit" components={{ main: RecipeForm }} />
						<Route path=":recipeId" components={{ main: Recipe }} />
					</Route>
					<Route
						path=":supplyId/produce"
						components={{ main: ProductionControlProduction }}
					/>
					<Route
						path="production/:productionId"
						components={{ main: ProductionControlViewProduction }}
					/>
				</Route>
				<Route path="cmv-report" components={{ main: CMVReport }} />
				<Route path="transfers" components={{ main: TransfersReport }} />
				<Route path="loss-report" components={{ main: LossReport }} />
				<Route path="extract" components={{ main: Extract }} />
				<Route path="fiscal-report" components={{ main: FiscalReport }} />
				<Route path="invoices" components={{ main: Invoices }} />
				<Route path="emission-errors" components={{ main: EmissionErrors }} />
				<Route path="missing-invoices" components={{ main: MissingInvoices }} />
				<Route path="trasactions" components={{ main: Transactions }} />
				<Route path="recharges" components={{ main: Recharges }} />
				<Route path="emited" components={{ main: Emited }} />
				<Route path="search" components={{ main: Search }} />
				<Route path="not-emited-products" components={{ main: NotEmitedProducts }} />
				<Route path="pos" components={{ main: POS }} />
				<Route path="zig-conta" components={{ main: ZigConta }} />
				<Route path="financial-report" components={{ main: FinancialReports }} />
				<Route path="bar-reports" components={{ main: BarReports }} />
				<Route path="table-reports" components={{ main: TableReports }} />
				<Route path="entrance-reports" components={{ main: EntraceReports }} />
				<Route path="delivery" components={{ main: DeliveryLayout }}>
					<Route path="menus" components={{ children: CoalaMenus }} />
					<Route path="edit-menu/:coalaMenuId" components={{ children: CoalaEditmenu }} />
					<Route path="products" components={{ children: CoalaProducts }} />
					<Route path="vendor-report" components={{ children: CoalaVendorReport }} />
					<Route path="order-report" components={{ children: CoalaOrderReport }} />
					<Route path="products-report" components={{ children: CoalaProductReport }} />
					<Route path="settings" components={{ children: DeliverySettings }} />
				</Route>
				<Route path="audits" components={{ main: Audits }} />
				<Route path="qual-boa" components={{ main: WhatsUp }} />
				<Route path="password-card" components={{ main: PasswordCard }} />
				<Route path="import-xml(/:ImportedInvoiceId)" components={{ main: ImportXml }} />
				<Route path="recharge-management" components={{ main: RechargeManagement }} />
				<Route
					path="operation-place-transactions-report"
					components={{ main: TransactionsReportPlace }}
				/>
				<Route path="financeiro">
					<IndexRoute components={{ main: Suppliers }} />
					<Route path="accounts">
						<Route path=":type" components={{ main: AccountsPayableReceivable }} />
						<Route
							path=":type/form(/:accountId)(/:preview)/(:status)"
							components={{ main: FormAccountsPayableReceivable }}
						/>
					</Route>
					<Route path="fornecedores" components={{ main: Suppliers }} />
					<Route path="tiposdecontas" components={{ main: BillType }} />
					<Route path="centrodecusto" components={{ main: CostCenter }} />
					{/* warning: código comentado até que seja liberado o desenvolvimento */}
					{/* <Route path="planodecontas" components={{ main: PlanOfAccounts }} /> */}
					{/* <Route path="relatorios" components={{ main: Reports }} /> */}
					<Route path="cashflow" components={{ main: Cashflow }} />
					<Route path="bank-accounts" components={{ main: BankAccounts }} />

					<Route path="dre" components={{ main: FinExManagerialReportPage }} />
					<Route path="conciliation" components={{ main: ConciliationPage }} />
					<Route
						path="event-conference(/:since)(/:until)"
						components={{ main: EventConference }}
					/>
				</Route>
				<Route path="event/:eventId">
					<IndexRoute components={{ main: EventPanel }} />
					<Route
						path="conference(/:previousPage)(/:since)(/:until)"
						components={{ main: Conference }}
					/>
					<Route path="device" components={{ main: DeviceWrapper }} />
					<Route path="reports" components={{ main: EventReports }} />
					<Route path="bar-reports" components={{ main: EventBarReports }} />
					<Route path="table-reports" components={{ main: EventTableReports }} />
					<Route path="clients" components={{ main: EventClients }} />
					<Route
						path="client-detail/:clientId"
						components={{ main: EventClientDetail }}
					/>
					<Route path="guest-lists" components={{ main: GuestLists }} />
					<Route path="guest-lists-report" components={{ main: GuestListsReport }} />
					<Route
						path="multivendor-products-sold"
						components={{ main: MultivendorProductsSoldEvent }}
					/>
					<Route
						path="multivendor-closings"
						components={{ main: MultivendorClosingsEvent }}
					/>
					<Route
						path="multivendor-revenues"
						components={{ main: MultivendorRevenuesEvent }}
					/>
					<Route path="reserve" components={{ main: EventReserve }} />
					<Route path="reserveReport" components={{ main: EventReserveReport }} />
					<Route path="entrance-reports" components={{ main: EventEntranceReports }} />
					<Route path="sellChip" components={{ main: SellChip }} />
					<Route path="analytic-payments" components={{ main: EventAnalyticPayments }} />
					<Route path="zig-tags" components={{ main: EventZigTags }} />
					<Route
						path="operation-orange-screen-report"
						components={{ main: OperationOrangeScreenReport }}
					/>
					<Route
						path="operation-event-actions-report"
						components={{ main: TransactionsReportEvent }}
					/>
					<Route path="recharge-management" components={{ main: RechargeManagement }} />
					<Route
						path="operation-totem-actions-report"
						components={{ main: OperationTotemActionsReport }}
					/>
					<Route path="hawkers-report" components={{ main: HawkersReportPage }} />
					<Route path="*" components={{ main: NoMatch }} />
				</Route>
				<Route path="*" components={{ main: NoMatch }} />
			</Route>
			<Route path="*" components={{ main: NoMatch }} />
		</Route>
	</Route>
);

export function Routes() {
	return (
		<Router history={history} onUpdate={sendPageview}>
			<Route
				onEnter={requireNotAuth}
				path="/recovery-password/:token"
				component={RecoveryPassword}
			/>
			<Route onEnter={requireNotAuth} path="/login" component={Login} />
			{privateRoutes}
		</Router>
	);
}
