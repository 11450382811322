import * as ReactGA from "react-ga";

import { CouvertModel } from "#models/couvert";
import enterprise from "#resources/api/enterprise-client";
import { EntranceModel } from "#models/entrance";
import { errorTap } from "#helpers/errors";
import { fetchModel } from "#resources/helpers/fetch-model";
import { jobExecutor } from "#resources/helpers/job-executor";
import { ProductModel } from "#models/product";
import { ReportModel } from "#models/report";
import { RootStore } from ".";
import { SupplyModel } from "#models/supply";
import { treatStringForSearch } from "#helpers/string";
import { uniqBy } from "lodash-es";

import { action, computed, observable } from "mobx";
import {
	BackofficeNewProductionRule,
	BackofficeProductionRuleInput,
	BaseBeerTap,
	BaseCategory,
	BasicStorageUnit,
	BeerTap,
	Category,
	CategorySubCategories,
	CreateSupply,
	CreateSupplyResponse,
	EditedProduct,
	EditProductImage,
	Entrance,
	NewCategory,
	NewPlaceProduct,
	Pagination,
	PatchProduct,
	PlaceProduct,
	PlaceProductsByTypeResponse,
	ProductKind,
	ProductRuleWithResume,
	ProductType,
	SellableListCombos,
	SellableListProducts,
	VerifyPatchProductsResult,
	VerifyPatchProductsType,
} from "#resources/api/enterprise-generated";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { ItemModel, ItemType } from "#models/item";
import {
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
	showWarningNotification,
} from "#helpers/notifications";
import { NewPlaceProductWithImage } from "#pages/event/event-products/interfaces";
import i18n from "#i18n/index";
import { BonusByProduct } from "#resources/types/reports/bonus-by-product";

export interface PlainCategory {
	id: string;
	name: string;
	parents: parent[];
	products: PlaceProduct[];
	parentName?: string;
}

export interface INewPlaceProductEdited extends NewPlaceProductWithImage {
	id: string;
}

interface parent {
	name: string;
	id: string;
}

interface ToggleArgs {
	placeId: string;
	productId: string;
}

const t = i18n.t;

export class ItemStore {
	@observable
	public items: ItemModel[] = [];

	@observable
	public percentageOfSuppliesSended = 0;

	@observable
	public addProductPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchitemsPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public deleteProductPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public editProductPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public changeProductQuantityPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public changeProductPricePromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public toogleProductsOnPlacePromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public bonusByProductReport: ReportModel<
		BonusByProduct[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getBonusByProduct(args));
	@observable
	public deleteProductFromPlacePromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public productKinds: ProductKind[] = [];

	@observable
	public addSupplyPromise: IPromiseBasedObservable<string> | null = null;

	@observable
	public editSupplyPromise: IPromiseBasedObservable<void> | null = null;

	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@computed
	public get products() {
		return this.items.filter(i => i.type === "Product") as ProductModel[];
	}

	@computed
	public get entrances() {
		return this.items.filter(i => i.type === "Entrance") as EntranceModel[];
	}

	@computed
	public get supplies() {
		return this.items.filter(i => i.type === "Supply") as SupplyModel[];
	}

	@computed
	public get couvers() {
		return this.items.filter(i => i.type === "Couvert") as CouvertModel[];
	}

	public clean() {
		this.items = [];
		this.addProductPromise = null;
		this.fetchitemsPromise = null;
		this.deleteProductPromise = null;
		this.editProductPromise = null;
		this.changeProductQuantityPromise = null;
		this.changeProductPricePromise = null;
		this.toogleProductsOnPlacePromise = null;
		this.deleteProductFromPlacePromise = null;
		this.getBeerTaps.reset();
		this.createBeerTap.reset();
		this.editBeerTap.reset();
		this.removeBeerTap.reset();
		this.getProductionRuleFromProductWithResume.reset();
	}

	public placeProducts = new fetchModel<{ placeId: string }, PlaceProduct[]>({
		fnPromise: args => enterprise.getPlaceProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public productsFromManyPlaces = new fetchModel<
		{ placeIds: string[] },
		PlaceProduct[][]
	>({
		fnPromise: ({ placeIds }) => {
			const prdPromise = placeIds.map(placeId =>
				enterprise.getPlaceProducts({ placeId }),
			);

			return Promise.all(prdPromise);
		},
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchitemsByPlace = async (placeId: string) => {
		this.fetchitemsPromise = fromPromise(
			enterprise.getPlaceProducts({ placeId }).then(items => {
				this.resolve(items);
			}),
		);

		// temporary fix
		this.rootStore.placeStore.getPlaceProductsWithCategories(placeId);

		await this.fetchitemsPromise;
	};

	@observable
	public categories: Category[] = [];

	@observable
	public filteredCategories: Category[] = [];

	@observable
	public searchInput: string = "";

	@action
	public searchCategory(input: string) {
		this.searchInput = input.toLowerCase();
		this.fetchCategories();
	}

	@action
	public cleanCategory() {
		this.searchInput = "";
		this.filteredCategories = this.categories;
	}

	@action
	private changeCategoryProductsStatus = (products: string[], status: boolean) => {
		this.items = this.items.map(i =>
			products.find(p => p === i.id) ? { ...i, active: status } : { ...i },
		);
	};

	private findNameInSubCategories(subCategories: CategorySubCategories[]) {
		let hasName = false;
		if (
			subCategories.filter(scategory =>
				treatStringForSearch(scategory.name).includes(
					treatStringForSearch(this.searchInput),
				),
			).length > 0
		)
			hasName = true;

		subCategories.forEach((scategory: CategorySubCategories) => {
			if (
				scategory.subCategories.filter(sscategory =>
					treatStringForSearch(sscategory.name).includes(
						treatStringForSearch(this.searchInput),
					),
				).length > 0
			)
				hasName = true;
		});

		return hasName;
	}

	@action
	public fetchCategories = async () => {
		if (this.searchInput !== "") {
			this.categories = await enterprise.getCategories();
			const filteredWithDuplicates = [
				...(await enterprise.filterSellablesCategories({ name: this.searchInput })),
				...this.categories.filter(
					category =>
						treatStringForSearch(category.name).includes(
							treatStringForSearch(this.searchInput),
						) || this.findNameInSubCategories(category.subCategories),
				),
			];

			this.filteredCategories = uniqBy(filteredWithDuplicates, "id");
		} else {
			this.categories = await enterprise.getCategories();
			this.filteredCategories = [...this.categories];
		}
	};

	@observable
	public productsFromCategory: {
		[index: string]: (
			| SellableListProducts
			| (SellableListCombos & { type: string | undefined })
		)[];
	} = {};

	@action
	public fetchProductsFromCategories = async (categoryId: string) => {
		try {
			const result = await enterprise.getSellablesFromCategory({ categoryId });
			this.productsFromCategory[categoryId] = [...result.combos, ...result.products] as (
				| SellableListProducts
				| (SellableListCombos & { type: string | undefined })
			)[];
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public activateProductsInPlace = async (placeId: string, products: string[]) => {
		const endLoading = showMessageOnTop({
			description: t("store:itemStore.activateProductsInPlaceLoading"),
			type: "loading",
		});

		try {
			await enterprise.activateProductsInPlace({ placeId, productIds: products });
			this.changeCategoryProductsStatus(products, true);
			endLoading();

			showMessageOnTop({
				description: t("store:itemStore.activateProductsInPlace"),
				time: 1000,
				type: "success",
			});
		} catch (err) {
			endLoading();
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public deactivateProductsInPlace = async (placeId: string, products: string[]) => {
		const endLoading = showMessageOnTop({
			description: t("store:itemStore.deactivateProductsInPlaceLoading"),
			type: "loading",
		});
		try {
			await enterprise.deactivateProductsInPlace({ placeId, productIds: products });
			this.changeCategoryProductsStatus(products, false);
			endLoading();

			showMessageOnTop({
				description: t("store:itemStore.deactivateProductsInPlace"),
				time: 1000,
				type: "warning",
			});
		} catch (err) {
			endLoading();
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public createCategory = async (category: NewCategory, data: Buffer | null) => {
		try {
			await enterprise.createCategory({ category, data });
			showSuccessNotification(t("store:itemStore.createCategory"));
			this.fetchCategories();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public editCategory = async (
		id: string,
		category: NewCategory,
		data: Buffer | null,
	) => {
		try {
			await enterprise.editCategory({ id, category, data });
			showSuccessNotification(t("store:itemStore.editCategory"));
			this.fetchCategories();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public deleteCategory = async (id: string) => {
		try {
			await enterprise.deleteCategory({ id });
			showSuccessNotification(t("store:itemStore.deleteCategory"));
			this.fetchCategories();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public resolve = (items: PlaceProduct[]) => {
		for (const item of items) {
			const itemModel = this.items.find(prod => prod.id === item.id);

			if (!itemModel) {
				switch (item.type as ItemType) {
					case "Product":
						this.items.push(new ProductModel(this, item));
						break;
					case "Couvert":
						this.items.push(new CouvertModel(this, item));
						break;
					case "Entrance":
						this.items.push(new EntranceModel(this, item));
						break;
					case "Supply":
						this.items.push(new SupplyModel(this, item));
						break;
					case "Tip":
						break;
					case "Combo":
						break;
					default:
						throw new Error(`unknown item type on item resolve:\n${item.type}`);
				}
			} else {
				itemModel.sync(item);
			}
		}
	};

	@action
	public addProduct = async (
		product: NewPlaceProduct,
		placeId: string,
		avatar: EditProductImage | null,
	) => {
		try {
			const placeProduct = await enterprise.addNewProduct({
				product,
				placeId,
				image: avatar,
			});

			ReactGA.event({
				category: "User",
				action: "create product",
			});

			this.fetchitemsByPlace(placeId);
			showSuccessNotification(t("store:itemStore.addProduct"));

			return placeProduct;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public importProducts = async (
		placeId: string,
		products: NewPlaceProductWithImage[],
	) => {
		let errorCounter = 0;
		const productsSortedByCategory = products.sort((a, b) =>
			a.category.localeCompare(b.category),
		);

		await jobExecutor(
			productsSortedByCategory,
			product =>
				enterprise.addNewProduct({
					product,
					placeId,
					image: product.image
						? { data: null, imageId: null, sha256: product.image }
						: null,
				}),
			undefined,
			(err, prod) => {
				errorCounter++;
				showErrorNotification(
					t("store:itemStore.importProductsError", {
						name: prod.name,
						message: err.message,
					}),
					45,
				);
			},
			1,
		);

		if (errorCounter === 0) {
			showSuccessNotification(
				t("store:itemStore.importProducts", {
					products: products.length,
				}),
			);
		} else {
			showWarningNotification(
				t("store:itemStore.importProductsWarning", {
					products: products.length - errorCounter,
					errorCounter,
				}),
				30,
			);
		}
	};

	@action
	public editProduct = async (
		placeId: string,
		productId: string,
		product: NewPlaceProduct,
		avatar: EditProductImage | null,
		sucessCallback?: () => void,
	) => {
		try {
			this.editProductPromise = fromPromise(
				enterprise
					.editPlaceProduct({
						id: productId,
						product,
						placeId,
					})
					.then(async () => {
						ReactGA.event({
							category: "User",
							action: "edit product",
						});

						if (sucessCallback) sucessCallback();

						if (avatar) {
							await enterprise.editProductImage({
								id: productId,
								image: avatar,
								placeId,
							});
						}

						await this.fetchitemsByPlace(placeId);
						showSuccessNotification(t("store:itemStore.editProduct"));
					})
					.catch(err => {
						if (err instanceof Error) {
							showErrorNotification(err.message);
						}
					}),
			);
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public importProductsToEdit = async (
		placeId: string,
		products: INewPlaceProductEdited[],
	) => {
		let errorCounter = 0;
		await jobExecutor<INewPlaceProductEdited, any>(
			products,
			({ id, ...prod }) =>
				enterprise.editPlaceProduct({
					id,
					product: prod,
					placeId,
					image: prod.image ? { data: null, imageId: null, sha256: prod.image } : null,
				}),
			undefined,
			(err, prod) => {
				errorCounter++;
				showErrorNotification(
					t("store:itemStore.importProductsToEditError", {
						name: prod.name,
						message: err.message,
					}),
					45,
				);
			},
		);

		if (errorCounter === 0) {
			showSuccessNotification(
				t("store:itemStore.importProductsToEdit", {
					products: products.length,
				}),
			);
		} else {
			showWarningNotification(
				t("store:itemStore.importProductsToEditWarning", {
					products: products.length - errorCounter,
					errorCounter,
				}),
				30,
			);
		}
	};

	@action
	public deleteProduct = (id: string) => {
		this.deleteProductPromise = fromPromise(
			enterprise
				.deleteProduct({ id })
				.then(rtn => {
					if (rtn.success) {
						this.items = this.items.filter(prod => prod.id !== id);
						showSuccessNotification(t("store:itemStore.deleteProduct"));
					} else {
						showErrorNotification(t("store:itemStore.deleteProductError"));
					}
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public deleteProductFromPlace = (placeId: string, id: string) => {
		this.deleteProductFromPlacePromise = fromPromise(
			enterprise
				.removeProductFromPlace({ placeId, productId: id })
				.then(() => {
					this.items = this.items.filter(prod => prod.id !== id);
					showSuccessNotification(t("store:itemStore.deleteProductFromPlace"));
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	public resolveManyIds = async (productIds: string[]) => {
		this.fetchitemsByPlace(this.rootStore.placeStore.place!.id!);
		await this.fetchitemsPromise;
		this.items = this.items.filter(product => productIds.includes(product.id));
		return this.products;
	};

	@action
	public resolveManyProducts = (products: PlaceProduct[]) => {
		this.resolve(products);
	};

	public async changeProductQuantity(
		productId: string,
		diff: number,
		placeId: string,
		barId: string,
		skipReload: boolean = false,
	) {
		this.changeProductQuantityPromise = fromPromise(
			//@ts-ignore
			enterprise.addProductInstances(productId, diff, barId).then(async () => {
				if (!skipReload) await this.fetchitemsByPlace(placeId);
				ReactGA.event({
					category: "User",
					action: "change places' stock for product",
				});
			}),
		);

		await this.changeProductQuantityPromise;
	}

	public changeProductPrice(placeId: string, productId: string, price: number) {
		const editedItem = this.products.find(prod => prod.id === productId);

		const previousPrice = editedItem?.value;
		if (editedItem) editedItem.value = price;

		this.changeProductPricePromise = fromPromise(
			enterprise
				.updatePriceAtPlace({ placeId, productId, value: price })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "change places' price for product",
					});
				})
				.catch(err => {
					if (editedItem && previousPrice !== undefined) editedItem.value = previousPrice;
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	}

	public toogleProductsOnPlace = (
		placeId: string,
		productIds: string[],
		enabled: boolean,
		includesMountableItems: boolean,
		isEntrance: boolean = false,
		showNotifications: boolean = true,
	) => {
		const apiFn = enabled
			? (args: ToggleArgs) =>
					enterprise.activateProductInPlace({
						...args,
						activateMountableItems: includesMountableItems,
					})
			: (args: ToggleArgs) =>
					enterprise.deactivateProductInPlace({
						...args,
						deactivateMountableItems: includesMountableItems,
					});

		const endLoading = showMessageOnTop({
			description: enabled
				? t(`store:itemStore.activating${isEntrance ? "Entrance" : "Product"}`)
				: t(`store:itemStore.deactivating${isEntrance ? "Entrance" : "Product"}`),
			time: 0,
			type: "loading",
		});

		return (this.toogleProductsOnPlacePromise = fromPromise(
			Promise.all(productIds.map(productId => apiFn({ placeId, productId })))
				.then(() => {
					this.fetchitemsByPlace(placeId);
					productIds.forEach(id => {
						const product = this.items.find(product => product.id === id);
						if (product) product.active = enabled;
					});

					ReactGA.event({
						category: "User",
						action: "toggle product availability at place",
					});
				})
				.then(() => {
					if (showNotifications) {
						showSuccessNotification(
							enabled
								? t(`store:itemStore.activated${isEntrance ? "Entrance" : "Product"}`)
								: t(`store:itemStore.deactivated${isEntrance ? "Entrance" : "Product"}`),
						);
					}

					endLoading();
				})
				.catch(err => {
					endLoading();

					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		));
	};

	public activateMountableSectionItem = new fetchModel<
		{
			placeId: string;
			mountableSectionId?: string | null;
			productId: string;
			activateAllItems: boolean;
		},
		void
	>({
		fnPromise: args => enterprise.activateMountableSectionItem(args),
		onSuccess: (_, args) => {
			showSuccessNotification(
				args.activateAllItems
					? t("store:itemStore.activateMountableSectionItem_all")
					: t("store:itemStore.activateMountableSectionItem_one"),
			);
		},
		onError: err => showErrorNotification(err.message),
	});

	public deactivateMountableSectionItem = new fetchModel<
		{
			placeId: string;
			mountableSectionId?: string | null;
			productId: string;
			deactivateAllItems: boolean;
		},
		void
	>({
		fnPromise: args => enterprise.deactivateMountableSectionItem(args),
		onSuccess: (_, args) => {
			showSuccessNotification(
				args.deactivateAllItems
					? t("store:itemStore.deactivateMountableSectionItem_all")
					: t("store:itemStore.deactivateMountableSectionItem_one"),
			);
		},
		onError: err => showErrorNotification(err.message),
	});

	public fetchBonusByProductReport = (eventId: string) => {
		this.bonusByProductReport.fetch({ args: { eventId } });
	};

	public fetchProductKinds = async () => {
		try {
			this.productKinds = await enterprise.getProductKinds();
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public createSupplies = async ({
		placeId,
		supplies,
	}: {
		placeId: string;
		supplies: CreateSupply[];
	}) => {
		try {
			const response = await enterprise.createSupply({
				supplies,
			});
			ReactGA.event({
				category: "User",
				action: "create supply",
			});

			if (!response.errors.length) {
				showSuccessNotification(t("store:itemStore.addSupply"));
				await this.fetchitemsByPlace(placeId);
			}

			return response;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}

			return null;
		}
	};

	@action
	public editSupply = (
		id: string,
		name: string,
		placeId: string,
		basicStorageUnit: BasicStorageUnit,
		fiscalCode: string | null = null,
		yieldPercentage: number | null = null,
	) => {
		this.editSupplyPromise = fromPromise(
			enterprise
				.editSupply({ id, placeId, name, basicStorageUnit, fiscalCode, yieldPercentage })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "edit supply",
					});

					showSuccessNotification(t("store:itemStore.editSupply"));
					this.fetchitemsByPlace(placeId);
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	public getAllEntrances = new fetchModel<{ placeId: string }, Entrance[]>({
		fnPromise: args => enterprise.getAllEntrances(args),
		onError: err => showErrorNotification(err.message),
	});

	public createEntrance = new fetchModel<
		{ placeId: string; entrance: Entrance; data: Buffer },
		string
	>({
		fnPromise: args => enterprise.createEntrance(args),
		onSuccess: (_, args) => {
			showSuccessNotification(t("store:itemStore.createEntrance"));
			this.getAllEntrances.fetch({ placeId: args.placeId });
		},
		onError: err => showErrorNotification(err.message),
	});

	public deleteEntrance = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.deleteEntrance(args),
		onSuccess: (_, args) => {
			showSuccessNotification(t("store:itemStore.deleteEntrance"));
			if (this.getAllEntrances.value !== undefined)
				this.getAllEntrances.value ===
					this.getAllEntrances.value.filter(e => e.id !== args.id);
		},
		onError: err => showErrorNotification(err.message),
	});

	public editEntrance = new fetchModel<{ placeId: string; entrance: Entrance }, void>({
		fnPromise: args => enterprise.editEntrance(args),
		onSuccess: (_, args) => {
			showSuccessNotification(t("store:itemStore.editEntrance"));
			this.getAllEntrances.fetch({ placeId: args.placeId });
		},
		onError: err => showErrorNotification(err.message),
	});

	public editEntranceImage = new fetchModel<{ entranceId: string; data: Buffer }, void>({
		fnPromise: args => enterprise.editEntranceImage(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.editEntranceImage")),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public organizationProductsWithCategories: PlainCategory[] = [];

	public findParents(
		allCats: BaseCategory[],
		catToFind: BaseCategory,
	): BaseCategory[] | null {
		if (catToFind.parentId === null) return null;
		const parent = allCats.find(c => c.id === catToFind.parentId);
		if (!parent) return null;
		if (parent.parentId) return [parent, ...(this.findParents(allCats, parent) || [])];
		else return [parent];
	}

	public plainSubCategoriesWithFathers(
		products: PlaceProduct[],
		plaincategories: BaseCategory[],
	): PlainCategory[] {
		const output: PlainCategory[] = plaincategories.map(cat => {
			const rtn = this.findParents(plaincategories, cat);

			return {
				id: cat.id,
				name: cat.name,
				parents: rtn ? rtn.map(c => ({ name: c.name, id: c.id })) : [],
				products: products.filter(product => product.categoryId === cat.id),
			};
		});

		return output;
	}

	@observable
	public isFetchingGetOrganizationProductsWithCategories: boolean = false;

	@action
	public getOrganizationProductsWithCategories = async () => {
		if (!this.isFetchingGetOrganizationProductsWithCategories) {
			this.isFetchingGetOrganizationProductsWithCategories = true;
			try {
				const placeProducts = this.products;
				const plaincategories = await enterprise.getPlainCategories();
				this.organizationProductsWithCategories = this.plainSubCategoriesWithFathers(
					placeProducts,
					plaincategories,
				);
			} catch (err) {
				errorTap(e => showErrorNotification(e.message));
			}
			this.isFetchingGetOrganizationProductsWithCategories = false;
		}
	};

	@action
	public fetchProductionRuleFromProduct = async (productId: string, placeId: string) => {
		try {
			return await enterprise.getProductionRuleFromProduct({ productId, placeId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public createProductionRule = async (
		placeId: string,
		rule: BackofficeNewProductionRule,
	) => {
		try {
			return await enterprise.createBackofficeProductionRule({ placeId, rule });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public attachProductionRule = async (
		productId: string,
		placeId: string,
		productionRuleId: string,
		fetchingItems = true,
	) => {
		try {
			await enterprise.attachPlaceProductToProductionRule({
				productId,
				placeId,
				productionRuleId,
			});
			showSuccessNotification(t("store:itemStore.attachProductionRule"));
			fetchingItems && this.fetchitemsByPlace(placeId);
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public removeProductionRule = async (productId: string, placeId: string) => {
		try {
			await enterprise.removePlaceProductProductionRule({ productId, placeId });
			showSuccessNotification(t("store:itemStore.removeProductionRule"));
			this.fetchitemsByPlace(placeId);
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public editProductionRule = async (
		placeId: string,
		productRuleId: string,
		inputs: BackofficeProductionRuleInput[],
	) => {
		try {
			await enterprise.editBackofficeProductionRuleInputs({
				placeId,
				productionRuleId: productRuleId,
				inputs,
			});
			showSuccessNotification(t("store:itemStore.editProductionRule"));
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public deleteProductionRule = async (placeId: string, ruleId: string) => {
		try {
			await enterprise.deleteBackofficeProductionRule({ placeId, ruleId });
			this.fetchitemsByPlace(placeId);
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public editMultipleProducts = async (products: EditedProduct[]) => {
		try {
			const result = await enterprise.editProducts({ products });
			if (result.error.length > 0) {
				result.error.forEach(e => {
					showErrorNotification(
						t("store:itemStore.editMultipleProductsError", {
							name: e.product.name,
							reason: e.reason,
						}),
					);
				});
			}

			if (result.success.length > 0) {
				result.success.forEach(e => {
					showSuccessNotification(
						t("store:itemStore.editMultipleProducts", {
							name: e.name,
						}),
					);
				});
			}
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public deleteMultipleProducts = async (productIds: string[], placeId: string) => {
		try {
			await enterprise.deleteMultipleProducts({ productIds, placeId });
			showSuccessNotification(t("store:itemStore.deleteMultipleProducts"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public getBeerTaps = new fetchModel<
		{
			placeId: string;
		},
		BeerTap[]
	>({
		fnPromise: args => enterprise.getBeerTaps(args),
		onError: err => showErrorNotification(err.message),
	});

	public createBeerTap = new fetchModel<
		{
			placeId: string;
			tap: BaseBeerTap;
		},
		void
	>({
		fnPromise: args => enterprise.createBeerTap(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.createBeerTap")),
		onError: err => showErrorNotification(err.message),
	});

	public editBeerTap = new fetchModel<
		{
			tap: BeerTap;
		},
		void
	>({
		fnPromise: args => enterprise.editBeerTap(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.editBeerTap")),
		onError: err => showErrorNotification(err.message),
	});

	public removeBeerTap = new fetchModel<
		{
			id: string;
		},
		void
	>({
		fnPromise: args => enterprise.removeBeerTap(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.removeBeerTap")),
		onError: err => showErrorNotification(err.message),
	});

	public changeBeerTapStatus = new fetchModel<
		{ placeId: string; id: string; status: boolean },
		void
	>({
		fnPromise: args => enterprise.changeBeerTapStatus(args),
		onError: err => showErrorNotification(err.message),
	});

	public verifyPatchProducts = new fetchModel<
		{
			products: PatchProduct[];
			placeId: string;
			type?: VerifyPatchProductsType | null;
		},
		VerifyPatchProductsResult | null
	>({
		fnPromise: args => enterprise.verifyPatchProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public patchProducts = new fetchModel<
		{
			products: PatchProduct[];
			placeId: string;
			activeAllProducts?: boolean | null;
		},
		string[]
	>({
		fnPromise: args => enterprise.patchProducts(args),
		onSuccess: (_, args) =>
			showSuccessNotification(
				t("store:itemStore.importProducts", {
					products: args.products.length,
				}),
			),
		onError: err => showErrorNotification(err.message, 30),
	});

	public getProductionRuleFromProductWithResume = new fetchModel<
		{ productId: string; placeId: string },
		ProductRuleWithResume
	>({
		fnPromise: args => enterprise.getProductionRuleFromProductWithResume(args),
		onError: error => showErrorNotification(error.message),
	});

	public getEntrance = new fetchModel<{ placeId: string; id: string }, Entrance | null>({
		fnPromise: args => enterprise.getEntrance(args),
		onError: error => showErrorNotification(error.message),
	});

	public getPlaceProductsByType = new fetchModel<
		{
			placeId: string;
			productType: ProductType;
			productName?: string | null | undefined;
			pagination?: Pagination | null | undefined;
		},
		PlaceProductsByTypeResponse
	>({
		fnPromise: args => enterprise.getPlaceProductsByType(args),
		onError: error => showErrorNotification(error.message),
	});

	public createSupply = new fetchModel<
		{
			supplies: CreateSupply[];
		},
		CreateSupplyResponse
	>({
		fnPromise: args => enterprise.createSupply(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.addSupply")),
		onError: error => showErrorNotification(error.message),
	});

	public newEditSupply = new fetchModel<
		{
			id: string;
			placeId: string;
			name: string;
			basicStorageUnit: BasicStorageUnit;
			fiscalCode?: string | null;
			yieldPercentage?: number | null;
			image?: EditProductImage | null;
		},
		void
	>({
		fnPromise: args => enterprise.editSupply(args),
		onSuccess: () => showSuccessNotification(t("store:itemStore.addSupply")),
		onError: error => showErrorNotification(error.message),
	});
}
