export const fiscal = {
	fieldsIvaFactura: {
		apikey: {
			label: "API Key",
			placeholder: "Insert API key",
		},
		secretkey: {
			label: "API Secret Key",
			placeholder: "Insert API secret key",
		},
		fiscalProductGroup: {
			label: "Fiscal Product Group",
			placeholder: "Choose fiscal product group",
		},
		autoInvoicingURL: {
			label: "Auto-Invoicing URL",
			placeholder: "Insert auto-invoicing URL",
		},
		issuerDocument: {
			label: "Issuer RFC",
			placeholder: "Insert issuer RFC",
		},
		expiration: {
			label: "Expiration Date",
			placeholder: "Choose an option for expiration date",
			expirationDaysLabel: "Days after transaction date",
			expirationDaysPlaceholder: "Choose days after transaction date",
			expirationDaysHint:
				"By setting a deadline that exceeds the transaction's creation month, the expiration date will be the last day of the month.",
			lastMonthDay: "On the last day of the month",
			daysAfterTransaction: "Days after transaction date",
		},
	},
	fieldsIvaVendus: {
		apikey: {
			label: "API key",
			placeholder: "Insert API key",
		},
		cashierIds: {
			label: "Cashier IDs",
			placeholder: "Insert cashier IDs",
		},
		country: {
			label: "Country",
			placeholder: "Select Country",
			options: {
				PT: "PT - Portugal",
				ES: "ES - Spain",
			},
		},
		paymentMethodIds: {
			labelCash: "Cash",
			labelCredit: "Credit",
			labelDebit: "Debit",
			labelMbway: "Mbway",
			labelOthers: "Others",
			labelPayment: "Payment method IDs:",
			placeholderPaymentId: "Insert ID",
		},
	},
	fieldsNfce: {
		certificate: {
			label: "Certificate",
		},
		certificatePassword: {
			label: "Certificate password",
			placeholder: "Insert certificate password",
		},
		cscNfceHomologation: {
			label: "CSC NFC-e homologation",
			placeholder: "Insert CSC NFC-e homologation",
		},
		cscNfceProduction: {
			label: "CSC NFC-e production",
			placeholder: "Insert CSC NFC-e production",
		},
		expirationDate: {
			label: "Expiration date",
			placeholder: "00/00/0000",
		},
		idNfceHomologation: {
			label: "ID NFC-e homologation",
			placeholder: "Insert ID NFC-e homologation",
		},
		idNfceProduction: {
			label: "Id NFC-e production",
			placeholder: "Insert ID NFC-e production",
		},
	},
	fieldsSat: {
		serialNumberSat: {
			label: "Serial number SAT",
			placeholder: "Enter serial password",
		},
	},
	optionsTaxation: {
		SimpleNationalExcess: "Simple national excess",
		normalRegime: "Normal regime",
		simpleNational: "Simple national",
	},
};
