export const financialReport = {
	print: "Print report",
	letterhead: 'Issued by "{{name}}" at {{momentLT}} in {{momentL}}',
	menu: {
		financialReport: "Closure",
		promotionsReports: "Promotions",
		debtorsReport: "pen accounts",
		devolutionReport: "Card return per box",
		cashierClose: "Cashier closings",
		bonusReport: "Bonus",
		discountsReportForPlace: "Account discounts",
		refundedProductsReport: "Reversed products",
		seeRefundReports: "Services removed",
		bonusByProduct: "Bonus per product",
		openedBillPayment: "Payment of open accounts",
		reserveReport: "Package report",
		rappiDiscountsReport: "Rappi discounts",
		consumptionObligationReport: "Minimum consumption",
		minimumConsumptionReport: "Early consumption",
		openSalesReport: "Single sales",
		transactionTransferReport: "Bill transfers",
	},
	tabs: {
		financialReport: {
			filename: "resume_report",
			title: "Financial from {{placeName}}",
			seeAllPaymentsButton: "See all payments",
			analyticPayments: {
				title: "Analytical report",
				columns: {
					date: "Date",
					author: "Employee",
					client: "Client",
					method: "Method",
					value: "Value",
					status: "Status",
				},
				searchName: "Search by customer or employee...",
				searchMethod: "Select a method...",
				bonus: "Bonus per product",
			},
			cmv: {
				title: "CMV",
				name: "Name",
				value: "Value",
				valuesName: "Total cost of products sold",
			},
			goTo: "go to",
			columnSection: {
				value: "value",
				receipt: "recipe",
				percentage: "percentage",
				quantity: "quantity",
			},
		},
		salesTotemTransaction: {
			columns: {
				date: "Date",
				author: "Author",
				client: "Client",
				method: "Payment Method",
				operator: "Operator",
				totem: "totem",
				value: "Sales Value",
				details: "Details",
			},
			details: {
				title: "Transaction Details",
				refunded: "Refunded",
				products: "Products",
				transactionId: "Transaction ID",
				zigId: "Zig ID",
				close: "Close",
			},
			totalizer: {
				totalClients: "Total Clients",
				paymentWithBalance: "Payment with Balance",
				totalSales: "Total Sales",
				searchByIdOrCpf: "Search by ID or CPF",
				paymentMethod: "Payment Method",
				totemLabel: "Totem",
				enterOrSelectTotem: "Enter or Select Totem",
			},
		},
		promotionsReports: {
			title: "Promotions used from {{placeName}}",
			tableTite: "Mesa",
			promotionTitle: "Promoções",
			promotionReportLabel: "Promotions used",
			userPromotionReportLabel: "Promotions used by customer",
			promotionReportFileName: "used-promotions",
			userPromotionReportFileName: "used-promotions-by-customer",
			totalDiscount: "Total discount",
			totalUses: "Total usage",
			columns: {
				userName: "Name",
				userDocument: "Document",
				productName: "Product",
				promotionName: "Promotion",
				productCategory: "Category",
				uses: "Number of uses",
				totalDiscount: "Total discount",
			},
		},
		debtorsReport: {
			title: "Open accounts",
			goTo: "go to",
			clientInfo: "Customer Information",
			filename: "open-accounts",
			clientTotal_one: "Total ({{debtors}} client)",
			clientTotal_other: "Total ({{debtors}} clients)",
			modalTitleName: "Payment of {{userName}}",
			modalTitleDebts: "Payment of {{debts}} bills",
			columns: {
				username: "Name",
				document: "Document",
				phone: "Telephone",
				date: "Date",
				value: "Account open",
				tipValue: "Open in service",
				totalOpen: "Total open",
				paidValue: "Paid after event",
				stillOpen: "Still open",
				filters: {
					paid: "Already paid",
					unpaid: "I do not pay",
				},
				others: {
					allBillsPaid: "All bills paid",
					payAllBills: "Pay all bills",
					paid: "Already paid",
					paymentOptions: "Payment options",
					pixCharge: "Generate PIX charge",
				},
				status: {
					title: "Billing Status",
					paid: "Paid",
					unpaid: "To pay",
				},
			},
			search: "Search customer...",
			paymentLink: {
				sendCharge: "Charge",
				generatedLink: "Generated link",
				charged: "Charged",
				noReturn: "No return",
				cancel: "Cancel",
				discharge: "Discharge",
				chooseShippingMethod: "Choose shipping method",
				finalPaymentLink: "Copy the link for tracking or to send later",
				error: "The number provided by the customer does not have whatsapp.",
				details: "Details {{userName}}",
				openValues: "Open Values",
				openConsumption: "Open Consumption",
				totalDebit: "Total Debit",
				tip: "Tip",
				mail: "Email",
				phone: "Phone",
				consumedProducts: "Consumed Products",
				viewBillingDetails: "View details",
				status: {
					created: "Generated link",
					notCharged: "Not charged",
					expired: "No response",
					partiallyCreated: "Partially charged",
				},
				copyError: "Error copying link",
				copySuccess: "Copied successfully",
				whatsappMessage:
					"Hello {{userName}}, we hope you are doing well. We would like to inform you that you have a pending account at {{placeName}}." +
					"For your convenience, we have provided a secure payment link where you can make the payment quickly and easily: {{link}}." +
					"If you have already made the payment, please disregard this message. Otherwise, we appreciate your cooperation in resolving this matter.",
				emailError: "Error sending email",
				emailSuccess: "Email sent successfully",
				paidBillMessage: "It is not possible to charge a bill that has been paid.",
				resendBill: "Resend",
				statusTooltip: {
					created: "Payment link generated for this customer.",
					notCharged: "No payment link has been generated to charge this customer.",
					partiallyCreated:
						"A payment link has been generated for this customer, but they have another outstanding account in the period that was not charged in the previous link.",
					expired:
						"The payment link was generated more than 7 days ago and has not been paid yet.",
				},
				selectService:
					"Do you want to include the service fee in the outstanding amount?",
				selectServiceText:
					"By choosing to include the service fee, the fee amount will be added to the outstanding balance of the account.",
				dontIncludeService: "Do not include",
				includeService: "Include service fee",
				seeMore: "See more",
				copyLink: "Copy payment link",
				copyValidLink: "Make sure this is a valid link",
				retrieveLink: "Retrieve billing link",
				moreOptions: "More options",
				confirmRemoveTip:
					"By removing the service from the charge via link, the amount will be set to zero and cannot be resumed.",
				wishToProceed: "Do you wish to proceed?",
			},
		},
		devolutionReport: {
			title: "Card return per cashier",
			filename: "card-return-per-cashier",
			sheetTitle: "Card Return {{sheetName}}",
			filenameCard: "return-card",
			total: "Total",
			totalCategory: "Total ({{category}})",
			totalGeral: "Grand total",
			cashier: "By cashiers",
			cashDevolution: "Cash refund",
			devolution: "Devolution in {{method}}",
			devolutions: "{{value}} devolutions",
			columns: {
				employee: "Employee",
				quantity: "Quantity",
				value: "Value",
				type: "Type",
			},
		},
		cashierClose: {
			title: "Cashier closings",
			resume: "Summary",
			cashiers: "Boxes",
			search: "Fetch box",
			searchBars: "Search bars",
			excelExport: "Export to excel",
			changeFund: "Change fund",
			modality: "Modality",
			billed: "Billed",
			closed: "Closed",
			difference: "Difference",
			methods: {
				cash: "Cash",
				debit: "Debit",
				credit: "Credit",
				voucher: "Voucher",
				zigDebit: "Zig Debit",
				zigCredit: "Zig Credit",
				postEvent: "Post event",
				dashboard: "Dashboard",
				anticipated: "Anticipated",
				deliveryOnline: "Online Delivery",
				ame: "Ame",
				rappi: "Rappi",
				uber: "Uber",
				ifood: "Ifood",
				pix: "Pix",
				bonus: "Bonus",
				installment: "Installment",
				foodVoucher: "Food Voucher",
			},
			hawkers: "Hawkers",
			hawkerCashier: "Hawkers Cashier",
		},
		bonusReport: {
			title: "Bonus",
			description: "See all bonus granting and usage reports",
			filenameBonus: "bonus-granted",
			filenameAnticipated: "anticipated-consumption",
			filenameComsumption: "consumption",
			total: "Total of {{category}}",
			tabs: {
				given: "Bonus Granted",
				used: "Bonus Used",
			},
			usedBonus: "Used Bonuses",
			columns: {
				client: "Client",
				date: "Date",
				dateTime: "Date and Time",
				reason: "Reason",
				receivedValue: "Received Value",
				spentValue: "Spent Value in the period",
				spentValueInOtherEvents: "Spent Value in another period",
				spentValueInBonus: "Payment Value in Bonus",
				givenBy: "Given by",
				total: "Total",
			},
		},
		discountsReportForPlace: {
			title: "Discounts used from {{placeName}}",
			discounts: "Discounts",
			filename: "discounts",
			columns: {
				employee: "Employee",
				date: "Date",
				clients: "Customers",
				justify: " Justification",
				category: "Category",
				products: "Products",
				percentage: "Percentage",
				discount: "Discount",
				total: "Total",
			},
			filter: "Filter by category",
			selectCategory: "Choose category",
		},
		refundedProductsReport: {
			title: "Reversed products",
			titleRecharges: "Refunded recharges",
			filenameRecharges: "refunded-recharges",
			columns: {
				date: "Date",
				client: "Client",
				author: "Recharge made by",
				refundedBy: "Reversed by",
				totalValue: "Amount",
				usedValue: "Value used",
			},
		},
		seeRefundReports: {
			title: "Removal of services by cashier",
			printButton: "Print report",
			exportButton: "Export report",
		},
		bonusByProduct: {
			title: "Bonus per product",
			filename: "bonus_per_product",
			columns: {
				product: "Product",
				category: "Category",
				value: "Bonus received in the period",
				bonusSpentFromOtherEvents: "Bonus received in another priod",
				total: "Total",
				totalCategory: "Total ({{category}})",
			},
			cards: {
				totalProductsValue: "Total bonus in Products",
				totalTipValue: "Total bonus in Tip",
				totalValue: "Total",
				receivedInPeriod: "Received in period",
				receivedAtAnotherPeriod: "Received in other period",
			},
		},
		openedBillPayment: {
			title: "Open account payments",
			tooltip: "Customer information",
			columns: {
				date: "Payday",
				client: "Client",
				document: "Document",
				openedEventDate: "Open account date",
				employee: "Paid for",
				method: "Method",
				value: "Value",
				total: "Total",
				observation: "Observation",
				tipValue: "Tip value",
			},
			mixed: "Mixed",
			emptyText: "No data to display.",
		},
		reserveReport: {
			title: "Packages",
			withoutPackage: "No active packages during the period",
			onePackage: "An active package during the period",
			manyPackages: "{{quantity}} packages active during the period",
			columns: {
				name: "Package name",
				products: "Products",
				invitedPeople: "Guests",
				confirmedPeople: "Confirmed",
				value: "Package value",
				revenue: "Total billing",
				issued: "Invoice issuance",
			},
			moreDetails: "More details",
			total: "Total",
			totalCategory: "Total ({{category}})",
		},
		consumptionObligationReport: {
			title: "Minimum consumption",
			filename: "minimum-consumption",
			filenameObligation: "consumption-obligation",
			columns: {
				date: "Date",
				client: "Client",
				employee: "Given by",
				value: "Value",
				userProductsValueAtEvent: "Used in products",
				paidValue: "Consummation surplus",
				total: "Total",
			},
		},
		minimumConsumptionReport: {
			title: "Early consumption",
		},
		openSalesReport: {
			title: "Single sales report",
			searchPlaceholder: "Search by employee name",
			employeeSales: "One-time sales of {{employeeName}}",
			columns: {
				employee: "Employee",
				total: "Total",
				date: "Date",
				isRefunded: "Was refunded",
				refundedBy: "Refunded by",
				refundedAt: "ERefunded at",
				value: "Value",
				method: "Method",
				yes: "yes",
				no: "no",
			},
			seeAllSales: "See all sales",
			totalSales: "Total open sales",
		},
		rappiDiscountReport: {
			title: "Rappi Discounts",
			sheet: {
				filename: "rappi_discounts_report",
				title: "Rappi Discounts",
				columns: {
					products: "Products",
					date: "Date",
					value: "Discount amount",
					employee: "Employee who applied the discount",
				},
			},
		},
		zigAppReport: {
			totemLabel: "Totem",
			title: "Sales by Zig.app transactions",
			searchByClientName: "Search by client name",
			searchByIdOrCpf: "Search by ID or CPF",
			enterOrSelectTotem: "Enter or select the totem",
			paymentMethod: "Payment method",
			balance: "Balance",
			creditCard: "Credit card",
			refunded: "Refunded",
			creditCardPayment: "Credit card payment",
			paymentWithBalance: "Payment with balance",
			averageTicket: "Average Ticket",
			totalSales: "Total sales",
			totalClients: "Total clients",
			date: "Date",
			client: "Client",
			clients: "Clients",
			operator: "Operator (POS)",
			details: "Details",
			totalPeriod: "Total Period",
			transactionDetail: "Transaction Details",
			products: "Products",
			zigId: "Zig ID",
			transactionId: "Transaction ID",
			copyTextSuccess: "ID copied successfully!",
			total: "Total paid with Zig.app",
			service: "Tip",
			close: "Close",
			justification: "Justification",
			refundTransaction: "Refund transaction",
			cancelTransaction: "Cancel transaction",
			optional: "Optional",
			charactersLimit: "Up to 150 characters",
			inputCancelationPlaceholder: "Write the reason for the refund",
			cancelAlertMessage:
				"When canceling, the products will be returned to stock and the action CANNOT be undone. Do you want to continue anyway?",
			serviceCharge: "Service Charge",
			status: "Status",
			selectRefundProductsList: "Products:",
			shouldGoBackToStock: "Should products be returned to stock?",
			inputJustificationPlaceholder: "Write the reason for the refund",
			refundAdlertMessage:
				"When refunding, the products WILL NOT be returned to stock and the action cannot be undone. Do you want to continue anyway?",
		},
		transactionTransferReport: {
			title: "Transaction transfers",
			fileName: "transfer_report",
			columns: {
				originCardId: "Originating Card ID",
				receiverCardId: "Receiving Card ID",
				originDocument: "Original customer's document",
				receiverDocument: "Receiver's document",
				productValue: "Product value",
				productDiscountValue: "Discount",
				productName: "Product's name",
				employeeName: "Employee's name",
				date: "Time",
			},
		},
	},
	getSubTitle: "From {{since}} to {{until}}",
};
