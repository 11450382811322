import { createQueryFactory } from "../query-factory";

export const { useApiHook: useSimplifiedPlaces } = createQueryFactory(
	"getSimplifiedPlaces",
);

export const { useApiHook: useGetPlaceProducts } = createQueryFactory("getPlaceProducts");

export const { useApiHook: useGetAddressFromCep } = createQueryFactory(
	"getAddressFromCep",
);
export const { useApiHook: useGetPlaceSettingsGeneralSection } = createQueryFactory(
	"getPlaceSettingsGeneralSection",
);

export const { useApiHook: useGetPlaceFeatures } = createQueryFactory("getPlaceFeatures");

export const { useApiHook: useGetPlace } = createQueryFactory("getPlace");
