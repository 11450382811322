/* eslint-disable max-len */

export const CONCILIATION_PT_BR = {
	pageTitle: "Conciliação via OFX",
	pageParagraph:
		"Ao baixar o extrato da sua conta corrente ou poupança em arquivo OFX , disponível junto aos principais bancos, e importar no dashboard você ganha ganha tempo na gestão do dinheiro de seu negócio.",
	importModal: {
		title: "Importação OFX",
		accountBankNotRegisteredTitle: "Conta bancária não cadastrada",
		triggerButton: "Importar arquivo OFX",
		cancelButton: "Cancelar",
	},
	availableBanks: "Bancos disponíveis",
	banks: {
		itau: "Itaú Unibanco",
		bb: "Banco do Brasil",
		bradesco: "Bradesco",
		cef: "Caixa Econômica Federal",
		santander: "Santander",
		nubank: "Nubank",
	},
	uploadOfxForm: {
		inputInstruction: "Clique ou arraste o arquivo OFX",
		fileType: "Arquivo OFX",
		description:
			"Exporte o arquivo OFX da sua conta contendo apenas os lançamentos para que não sejam geradas movimentações indevidos",
		submitButton: "Importar",
		validation: {
			fileRequired: "O arquivo OFX é obrigatório",
			fileSize: "O arquivo OFX deve ter no máximo 5 MB",
		},
	},
	submitOfxForm: {
		nameFile: "Nome do arquivo:",
		periodImported: "Período importado:",
		periodImportedValue: "{{start}} até {{end}}",
		bankName: "Instituição bancária:",
		labelCurrency: "Saldo final em {{date}}",
		labelCheckValue: "Confirmo o valor de {{value}} no fim deste extrato",
		labelAccounts: "Selecione uma conta:",
		placeholderAccount: "Clique para selecionar",
		accountRequired: "Selecione uma conta",
		submitButton: "Confirmar valor",
		alerts: {
			accountNotCreated: {
				title: "Conta bancária não encontrada",
				description:
					"Não identificamos essa conta bancária entre as cadastradas. <a>Clique aqui</a> para realizar o cadastro <b>ou selecione uma conta já cadastrada abaixo</b> para prosseguir com a importação do OFX.",
			},
			accountNotValidated: {
				title: "Esta conta ainda não foi validada.",
				description:
					"Essa conta bancária foi cadastrada com o nome {{name}} mas ainda não teve seus dados validados. Utilizaremos os dados exportados nesse OFX para realizar a validação automática.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Importação realizada com sucesso",
			itemsImported: "{{send}} lançamentos foram importados",
			balance: "Saldo final de {{balance}} em {{date}}",
		},
		error: {
			title: "Ops... Algo não saiu como planejado",
			description:
				"Não conseguimos realizar a importação do OFX. Tente novamente mais tarde.",
		},
		confirmButton: "Conciliar movimentações",
	},
	redirectBankFlow: {
		description:
			"O cadastro da conta bancária é uma ação necessária antes da importação do OFX. ",
		redirectButton: "Cadastrar conta",
	},
	tabs: {
		pendingConciliations: "Conciliações pendentes",
		history: "Histórico",
		description: {
			pendingConciliations: "Detalhes das importações",
			history: "Histórico de conciliações",
		},
		pendingTab: {
			emptyState: {
				title: "Não existem conciliações pendentes no momento",
				description: "Importe um novo arquivo OFX para conciliar novos lançamentos",
			},
		},
		historyTab: {
			emptyState: {
				title: "Nenhuma conciliação encontrada",
			},
		},
	},
	collapse: {
		branch: "Agência",
		account: "Conta",
		importedDate: "Período importado",
		importedDateDescription: "De {{since}} até {{until}}",
		conciliationDate: "Data de conciliação",
		actions: {
			undoConciliation: "Desfazer conciliação",
		},
		unConciliatedAmount: "{{amount}} não conciliadas",
		item: {
			value: "Valor",
			date: "Data",
			description: "Descrição",
			chartOfAccount: "Plano de contas",
			paymentMethod: "Forma de pagamento",
			status: {
				conciliated: "Conciliada",
				refused: "Ignorada",
			},
			tooltip: {
				search: "Buscar lançamento",
				addAccount: "Adicionar conta",
				ignoreTransaction: "Ignorar transação",
			},
			accountType: {
				CheckingAccount: "Conta corrente",
				SavingsAccount: "Conta poupança",
			},
			paymentTypes: {
				CREDIT: "Crédito",
				DEBIT: "Débito",
				INT: "INT",
				DIV: "DIV",
				FEE: "FEE",
				SRVCHG: "SRVCHG",
				DEP: "DEP",
				ATM: "ATM",
				POS: "POS",
				XFER: "XFER",
				CHECK: "Cheque",
				PAYMENT: "PAYMENT",
				CASH: "Dinheiro",
				DIRECTDEP: "DIRECTDEP",
				DIRECTDEBIT: "Debito Direto",
				REPEATPMT: "REPEATPMT",
				OTHER: "Outros",
				CreditCard: "Cartão de Credito",
				DebitCard: "Cartão de Debito",
				Pix: "Pix",
			},
		},
		cancel: "Cancelar",
		complete: "Concluir conciliação",
	},
	transactionSuccessNotification: "Transação atualizada com sucesso!",
	cancelButton: "Cancelar",
	confirmButton: "Confirmar",
	conciliateConciliation: {
		title: "Concluir conciliação",
		description:
			"Ao concluir a conciliação os lançamentos ignorados serão <b>excluídos permanentemente</b> das suas conciliações. Deseja continuar?",
		error: "Ainda possui transações pendentes!",
		success: "Conciliação realizada com sucesso!",
	},
	ignoreTransactionModal: {
		title: "Ignorar Transação",
		description: "Tem certeza de que deseja ignorar a transação <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Buscar lançamento",
		contentTitle: "Informações da importação",
		labelValue: "Valor",
		labelPaymentDate: "Data do pagamento",
		labelReceiptDate: "Data do recebimento",
		labelDescription: "Descrição",
		labelChartAccount: "Plano de contas",
		labelPaymentMethod: "Forma de pagamento",
		buttonCancel: "Cancelar",
		buttonConfirm: "Conciliar",
		valuesAreDifferentAlert:
			"O valor da transação é diferente do valor selecionado e não pode ser conciliado",
		table: {
			emptyIsFetched: "Nenhuma conta encontrada",
			emptyCreatePaymentButton: "Criar conta a pagar",
			emptyCreateReceiptButton: "Criar conta a receber",
			emptyInitial: {
				title: "Use as buscas para começar",
				subtitle:
					"Busque e selecione um lançamento para realizar a conciliação da transação abaixo.",
			},
			paymentDate: "Data",
			receiptDate: "Data",
			chartAccounts: "Plano de contas",
			paymentMethod: "Forma de pagamento",
			value: "Valor",
		},
	},
	undoIgnoreTransactionModal: {
		button: "Desfazer exclusão",
		title: "Desfazer exclusão",
		description:
			"Tem certeza de que deseja desfazer a exclusão da transação <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Desfazer conciliação",
		title: "Desfazer conciliação",
		description:
			"Tem certeza de que deseja desfazer a conciliação da transação <b>{{name}}</b>?",
	},
};

export type ConciliationTranslations = typeof CONCILIATION_PT_BR;
