export const fiscal = {
	fieldsIvaFactura: {
		apikey: {
			label: "Chave da API",
			placeholder: "Inserir chave da API",
		},
		secretkey: {
			label: "Chave Secreta da API",
			placeholder: "Inserir chave secreta da API",
		},
		fiscalProductGroup: {
			label: "Grupo fiscal de produto",
			placeholder: "Escolher grupo de fiscal de produto",
		},
		autoInvoicingURL: {
			label: "URL de autofaturação",
			placeholder: "Inserir URL de autofaturação",
		},
		issuerDocument: {
			label: "RFC do emissor",
			placeholder: "Inserir RFC do emissor",
		},
		expiration: {
			label: "Data de expiração",
			placeholder: "Escolha uma opção para data de expiração",
			expirationDaysLabel: "Dias após a data da transação",
			expirationDaysPlaceholder: "Escolher dias após a data da transação",
			expirationDaysHint:
				"Ao definir um prazo que ultrapasse o mês da criação da transação, a data de expiração será o último dia do mês.",
			lastMonthDay: "No último dia do mês",
			daysAfterTransaction: "Dias após a data da transação",
		},
	},
	fieldsIvaVendus: {
		apikey: {
			label: "Chave da API",
			placeholder: "Inserir chave da API",
		},
		cashierIds: {
			label: "ID dos caixas",
			placeholder: "Inserir ID dos caixas",
		},
		country: {
			label: "País",
			placeholder: "Selecionar país",
			options: {
				PT: "PT - Portugal",
				ES: "ES - Espanha",
			},
		},
		paymentMethodIds: {
			labelCash: "Dinheiro",
			labelCredit: "Crédito",
			labelDebit: "Débito",
			labelMbway: "Mbway",
			labelOthers: "Outros",
			labelPayment: "IDs das formas de pagamento:",
			placeholderPaymentId: "Inserir ID",
		},
	},
	fieldsNfce: {
		certificate: {
			label: "Certificado",
		},
		certificatePassword: {
			label: "Senha do certificado",
			placeholder: "Inserir senha do certificado",
		},
		cscNfceHomologation: {
			label: "CSC NFC-e homologação",
			placeholder: "Insert CSC NFC-e homologação",
		},
		cscNfceProduction: {
			label: "CSC NFC-e produção",
			placeholder: "Insert CSC NFC-e produção",
		},
		expirationDate: {
			label: "Data de expiração",
			placeholder: "00/00/0000",
		},
		idNfceHomologation: {
			label: "ID NFC-e homologação",
			placeholder: "Inserir ID NFC-e homologação",
		},
		idNfceProduction: {
			label: "Id NFC-e produção",
			placeholder: "Inserir ID NFC-e produção",
		},
	},
	fieldsSat: {
		serialNumberSat: {
			label: "Número de série SAT",
			placeholder: "Inserir senha de série SAT",
		},
	},
	optionsTaxation: {
		SimpleNationalExcess: "Simples nacional excesso",
		normalRegime: "Regime normal",
		simpleNational: "Simples nacional",
	},
};
