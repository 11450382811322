import { promotionInfo } from "./promotion-info";
import { awards } from "./awards";
import { resume } from "./resume";
import { sideTab } from "./side-tab";

export const promotions = {
	title: "Promoções",
	filter: {
		label: "Filtro",
		all: "Todas",
		active: "Ativas",
		inactive: "Inativas",
	},
	search: "Pesquise por nome da promoção",
	empty: "A busca não encontrou resultados",
	withoutPromotions: "Não existem promoções cadastradas",
	create: "Criar promoção",
	createPromotion: {
		promotionInfo,
		goals: {
			title: "Metas",
			description:
				"As metas são parâmetros que vão determinar o que o seu cliente precisa fazer para ganhar a promoção.",
		},
		awards,
		usageConditions: {
			title: "Condições de uso",
			description:
				"Nessa etapa você pode definir parâmetros de uso da promoção. Quando o cliente ganha a promoção, ele só pode utilizá-la se seguir essas condições de uso.",
			warning:
				'As condições de uso para "Compre e Ganhe" também são usadas para ganhar a promoção. Expiração não se aplica para "Compre e Ganhe".',
		},
		resume,
	},
	card: {
		subtitle: "Desconto de {{discount}}",
		withoutDiscount: "Sem Desconto",
		expiresAt: "Expira em {{date}} Dia(s)",
		noExpirationDate: "Sem prazo de expiração",
		active: "Ativa",
		inactive: "Inativa",
	},
	sideTab,
	selected: {
		title: "Nome da promoção",
		active: "Ativa",
		inactive: "Inativa",
		clientList: "Lista de clientes",
		clientListModal: {
			title: "Lista de clientes {{promotionName}}",
			addClient: {
				label: "Adicionar clientes à lista",
				placeholder: "Digite CPF do cliente",
				placeholderForeign: "Digite o telefone do cliente",
				addClient: "Adicionar cliente",
				foreign: "Estrangeiro",
			},
			search: "Busque por nome ou CPF",
			searchForeign: "Busque por nome ou telefone",
			remove: "Remover clientes selecionados",
			columns: {
				name: "Nome",
				cpf: "CPF",
				cpfForeign: "Documento",
				phone: "Telefone",
			},
			error: "Não foi possível adicionar os clientes abaixo:",
		},
		copy: "Criar cópia",
		discountValue: {
			title: "Desconto de {{discount}}",
			unavailable: "Indisponível",
		},
		maxUses: {
			title: "{{uses}} usos por pessoa",
			withoutLimit: "Sem limite de uso individual",
		},
		expirationTime: {
			title: "Expira em: {{date}} Dias",
			noExpires: "Sem prazo de expiração",
		},
		productsList: {
			title: "PRODUTOS EM PROMOÇÃO",
			all: "Todos os produtos",
			unavailable: "Produto indisponível",
			seeMore: "ver mais",
			seeLess: "ver menos",
		},
		erasePromotion: {
			title: "Apagar promoção",
			confimation: "Tem certeza que quer apagar a promoção?",
		},
	},
};
