import { drawerProfile } from "./drawer-profile";

export const drawers = {
	drawerProfile,
	editData: "Editar informações",
	viewlinkedplaces: "Ver locais vinculados",
	drawerCouvert: {
		code: "SKU",
		couvertGeneralData: "Dados gerais de couvert",
		taxGroup: "Grupo fiscal",
		taxProfile: "Perfil fiscal",
	},
	drawerGroup: {
		excludeTaxGroup: "Excluir grupo",
		titles: {
			generalData: "Dados gerais",
			vat: "IVA",
			service: "Serviço",
		},
		fiscalProductGroupType: {
			vat: "IVA Portugal",
			vatFactura: "IVA México",
			vatSpain: "IVA Espanha",
			service: "Serviço",
			invoice: "Fatura",
		},
		fields: {
			taxRate: {
				label: "Aliquota (%)",
			},
			code: {
				label: "SKU",
			},
			productType: {
				label: "Tipo de produto",
			},
			taxExemption: {
				label: "Isenção de imposto",
			},
			taxType: {
				label: "Tipo de imposto",
			},
		},
	},
};
