import { fetchModel } from "#resources/helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { RootStore } from ".";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import * as API from "#resources/api/enterprise-generated";
import { resetAllFetchModelsOnStore } from "#resources/helpers/fetch-model/store-helpers";
import { toJS } from "mobx";

type ArgsOfEndpoint<T extends keyof typeof enterprise> = typeof enterprise[T] extends (
	...args: any
) => any
	? Parameters<typeof enterprise[T]>[0]
	: never;

export class StorageStore {
	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean = () => {
		resetAllFetchModelsOnStore(this);
		this.storageIndex = {};
	};

	// INDEXES
	public storageIndex: Partial<Record<string, API.BackofficeStorageWithResume>> = {};

	public getStorageFromIndex = (
		storageId: string,
	): API.BackofficeStorageWithResume | null => {
		return this.storageIndex[storageId] ?? null;
	};

	private updateStorageIndex = (storages: API.BackofficeStorageWithResume[]) => {
		this.storageIndex = toJS(storages).reduce(
			(acc, cur) => ({ ...acc, [cur.id]: cur }),
			{},
		);
	};

	// CALLS

	public getBackofficeStorageWithResume = new fetchModel<
		{
			id: string;
			placeId: string;
		},
		API.BackofficeStorageWithResume
	>({
		fnPromise: args => enterprise.getBackofficeStorageWithResume(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStorageInsights = new fetchModel<
		{
			storageId: string;
			placeId: string;
		},
		API.BackofficeStorageInsights
	>({
		fnPromise: args => enterprise.getBackofficeStorageInsights(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStoragesInsights = new fetchModel<
		{
			placeId: string;
		},
		API.BackofficeStorageInsights
	>({
		fnPromise: args => enterprise.getBackofficeStoragesInsights(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStoragesWithResume = new fetchModel<
		{
			placeId: string;
		},
		API.BackofficeStorageWithResume[]
	>({
		fnPromise: args => enterprise.getBackofficeStoragesWithResume(args),
		onSuccess: storages => this.updateStorageIndex(storages),
		onError: err => showErrorNotification(err.message),
	});

	public createBackofficeStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			organizationId?: string | null | undefined;
			storage: API.BackofficeNewStorage;
			currentPlaceIdToRefreshStorages: string | null | undefined;
		},
		API.BackofficeStorage
	>({
		fnPromise: args => enterprise.createBackofficeStorage(args),
		onSuccess: (_rtn, args) => {
			if (args.currentPlaceIdToRefreshStorages)
				this.getBackofficeStoragesWithResume.fetch({
					placeId: args.currentPlaceIdToRefreshStorages,
				});
		},
		onError: err => showErrorNotification(err.message),
	});

	public editBackofficeStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			organizationId?: string | null | undefined;
			id: string;
			storage: API.BackofficeNewStorage;
			currentPlaceIdToRefreshStorages: string | null | undefined;
		},
		void
	>({
		fnPromise: args => enterprise.editBackofficeStorage(args),
		onSuccess: (_rtn, args) => {
			if (args.currentPlaceIdToRefreshStorages)
				this.getBackofficeStoragesWithResume.fetch({
					placeId: args.currentPlaceIdToRefreshStorages,
				});
		},
		onError: err => showErrorNotification(err.message),
	});

	public deleteBackofficeStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			id: string;
			currentPlaceIdToRefreshStorages: string | null | undefined;
		},
		void
	>({
		fnPromise: args => enterprise.deleteBackofficeStorage(args),
		onSuccess: (_rtn, args) => {
			if (args.currentPlaceIdToRefreshStorages)
				this.getBackofficeStoragesWithResume.fetch({
					placeId: args.currentPlaceIdToRefreshStorages,
				});
		},
		onError: err => showErrorNotification(err.message),
	});

	public importBackofficeInvoice = new fetchModel<
		{
			placeId: string;
			storageId?: string | null | undefined;
			xml: Buffer;
			restriction?: API.BackofficeImportedInvoiceRestriction | null;
		},
		API.BackofficeImportedInvoice
	>({
		fnPromise: args => enterprise.importBackofficeInvoice(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProuctsAtStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			storageId: string;
			filters: API.GetProductsAtStorageFilters;
		},
		API.StorageProduct[]
	>({
		fnPromise: args => enterprise.getProductsAtStorage(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceProducts = new fetchModel<{ placeId: string }, API.PlaceProduct[]>({
		fnPromise: args => enterprise.getPlaceProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public manualBackofficeAdjustProductAtStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			storageId: string;
			productId: string;
			count: number;
			date: Date;
			obs?: string | null | undefined;
		},
		void
	>({
		fnPromise: args => enterprise.manualBackofficeAdjustProductAtStorage(args),
		onSuccess: (_, { placeId, storageId }) => {
			showSuccessNotification("Item Removido do estoque");
			this.getProuctsAtStorage.fetch({
				storageId,
				placeId,
				filters: { onlyStockableProducts: true },
			});
		},
		onError: err => showErrorNotification(err.message),
	});

	public zeroBackofficeProductsAtStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			storageId: string;
			productIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.zeroBackofficeProductsAtStorage(args),
		onSuccess: (_, { placeId, storageId }) => {
			showSuccessNotification("Items zerados no estoque");
			this.getProuctsAtStorage.fetch({
				storageId,
				placeId,
				filters: { onlyStockableProducts: true },
			});
		},
		onError: err => showErrorNotification(err.message),
	});

	public inputBackofficeProductV2 = new fetchModel<
		{
			placeId?: string | null | undefined;
			input: API.BackofficeInputV2;
			transferType?: API.BackofficeStorageTransferType | null | undefined;
			referenceCode?: string | null | undefined;
		},
		void
	>({
		fnPromise: args => enterprise.inputBackofficeProductV2(args),
		onSuccess: (_, { placeId, input }) => {
			showSuccessNotification("Item Adicionado ao estoque");
			this.getProuctsAtStorage.fetch({
				storageId: input.storageId,
				placeId,
				filters: { onlyStockableProducts: true },
			});
		},
		onError: err => showErrorNotification(err.message),
	});

	public inputBackofficeProductsV2 = new fetchModel<
		{
			placeId?: string | null | undefined;
			inputs: API.BackofficeInputV2[];
			transferType?: API.BackofficeStorageTransferType | null | undefined;
			referenceCode?: string | null | undefined;
		},
		API.BackofficeInputsResultV2
	>({
		fnPromise: args => enterprise.inputBackofficeProductsV2(args),
		onSuccess: () => showSuccessNotification("Itens Adcionados ao estoque"),
		onError: err => showErrorNotification(err.message),
	});

	public transferBackofficeProductsV2 = new fetchModel<
		{
			placeId?: string | null | undefined;
			fromId: string;
			toId: string;
			date: Date;
			products: API.BackofficeMultipleStorageTransferProduct[];
			activateProducts: boolean;
		},
		API.BackofficeMultipleStorageTransferProductResultV2
	>({
		fnPromise: args => enterprise.transferBackofficeProductsV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteBackofficeProductAtStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			storageId: string;
			productId: string;
		},
		void
	>({
		fnPromise: args => enterprise.deleteBackofficeProductAtStorage(args),
		onSuccess: (_, { storageId, placeId }) => {
			this.getProuctsAtStorage.fetch({
				storageId,
				placeId,
				filters: { onlyStockableProducts: true },
			});
			showSuccessNotification("Itens removidos do estoque");
		},
		onError: err => showErrorNotification(err.message),
	});

	public deleteBackofficeProductsAtStorage = new fetchModel<
		{
			placeId?: string | null | undefined;
			storageId: string;
			productIds: string[];
		},
		API.BackofficeDeleteProductsResult
	>({
		fnPromise: args => enterprise.deleteBackofficeProductsAtStorage(args),
		onSuccess: (_, { storageId, placeId }) => {
			this.getProuctsAtStorage.fetch({
				storageId,
				placeId,
				filters: { onlyStockableProducts: true },
			});
			showSuccessNotification("Itens removidos do estoque");
		},
		onError: err => showErrorNotification(err.message),
	});

	public suppliesWithProductionRule = new fetchModel<
		ArgsOfEndpoint<"getSuppliesWithProductionRule">,
		API.SupplyWithProductionRule[]
	>({
		fnPromise: args => enterprise.getSuppliesWithProductionRule(args),
		onError: err => showErrorNotification(err.message),
	});

	public fetchProductionHistory = new fetchModel<
		ArgsOfEndpoint<"getBackofficeProductionHistoryForProducts">,
		API.BackofficeProductionHistoryResult
	>({
		fnPromise: args => enterprise.getBackofficeProductionHistoryForProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public fetchProduction = new fetchModel<
		ArgsOfEndpoint<"getBackofficeProduction">,
		API.Production
	>({
		fnPromise: args => enterprise.getBackofficeProduction(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportProductionHistory = new fetchModel<
		ArgsOfEndpoint<"exportBackofficeProductionHistoryForProducts">,
		string
	>({
		fnPromise: args => enterprise.exportBackofficeProductionHistoryForProducts(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public updateSupplyWithProductionRule = new fetchModel<
		ArgsOfEndpoint<"updateSupplyWithProductionRule">,
		API.SupplyWithProductionRule | null
	>({
		fnPromise: args => enterprise.updateSupplyWithProductionRule(args),
		onError: err => showErrorNotification(err.message),
	});

	public supplyWithProductionRule = new fetchModel<
		ArgsOfEndpoint<"getSupplyWithProductionRule">,
		API.SupplyWithProductionRule
	>({
		fnPromise: args => enterprise.getSupplyWithProductionRule(args),
		onError: err => showErrorNotification(err.message),
	});

	public applyProductionRule = new fetchModel<
		ArgsOfEndpoint<"applyBackofficeProductionRule">,
		void
	>({
		fnPromise: args => enterprise.applyBackofficeProductionRule(args),
	});

	public createSupplyWithProductionRule = new fetchModel<
		ArgsOfEndpoint<"createSupplyWithProductionRule">,
		API.SupplyWithProductionRule
	>({
		fnPromise: args => enterprise.createSupplyWithProductionRule(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteSupplyWithProductionRule = new fetchModel<
		ArgsOfEndpoint<"deleteSupplyWithProductionRule">,
		void
	>({
		fnPromise: args => enterprise.deleteSupplyWithProductionRule(args),
		onError: err => showErrorNotification(err.message),
	});

	public fetchProfitMarginReport = new fetchModel<
		ArgsOfEndpoint<"getProfitMarginReport">,
		API.ProfitMarginReportResult
	>({
		fnPromise: args => enterprise.getProfitMarginReport(args),
		onError: err => showErrorNotification(err.message),
	});
}
