export const chartOfAccounts = {
	titlePage: "Plano de contas",
	tableTitle: {
		revenues: "Receitas",
		costs: "Custos",
		expenses: "Despesas",
	},
	tableRows: {
		minimizeAll: "Minimizar",
		expandAll: "Expandir",
	},
	expandLevels: "Expandir níveis",
	categoryFixedInfo: "Categorias fixas não podem ser editadas e nem excluídas",
	viewCode: "Exibir código do plano de contas",
	usageInfo:
		"O Plano de contas financeiro é a relação das categorias de receitas e despesas de uma empresa. Por ser uma forma de classificar as movimentações financeiras, ele permite organizar e entender melhor a situação financeira ao olhar entradas e saídas.",

	titleModal: {
		edit: "Renomear conta",
		create: "Adicionar conta",
		exclude: "Excluir plano de contas",
		warning: "Este plano de contas possui vínculos",
	},
	titleActions: {
		edit: "Botão de edição",
		create: "Botão de criação",
		exclude: "Botão de exclusão",
	},
	modalForm: {
		fields: {
			nameChart: { label: "Nome da conta", placeholder: "Insira o nome da conta" },
			visibility: {
				label: "Visibilidade",
				options: {
					finExManagerialReport: "DRE",
					cashflow: "Fluxo de Caixa",
					both: "Ambos",
				},
				tooltip:
					"A visibilidade define se a conta aparecerá no DRE, Fluxo de Caixa ou ambos os relatórios",
			},
		},
		errors: {
			requiredField: "Campo obrigatório",
			characterLimitExceeded: "Limite de 70 caracteres excedido",
		},
		status: {
			successEdit: "Categoria editada com sucesso",
			successCreate: "Categoria criada com sucesso",
			successExclude: "Categoria excluida com sucesso",
		},
		content: {
			create:
				"Esta conta será adicionada no nível {{level}}, código {{code}}, como subcategoria de {{typeCategory}} na categoria {{name}}. Como você deseja nomeá-la?",
			edit: "Qual nome você deseja dar a essa conta?",
		},
	},
	modalExcludeChart: {
		warningContent:
			"Este plano de contas <strong>não pode ser deletado</strong> pois existem lançamentos vinculados ao mesmo.",
		excludeContent:
			'Ao confirmar a ação você exclui permanentemente o plano de contas de "{{name}}". Deseja continuar?',
		buttonExclude: "Sim, desejo excluir o plano",
	},
};
