import { autobind } from "core-decorators";
import { fetchModel } from "#helpers/fetch-model";
import { IError } from "#helpers/mobx/utils";
import { RootStore } from ".";
import { showErrorNotification } from "#helpers/notifications";

import {
	BackOfficeChartAccountResponse,
	BackOfficeOperationChartAccount,
} from "#resources/api/enterprise-generated";

import enterprise from "#resources/api/enterprise-client";

@autobind
export class ChartOfAccountsStore {
	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.getBackOfficeChartAccount.reset();
		this.getBackOfficeChartAccountTreeDefault.reset();
		this.getBackofficeChartAccounts.reset();
		this.exportBackofficeDefaultChartAccountXlsx.reset();
		this.importBackofficeChartAccountXlsx.reset();
	}

	public getBackOfficeChartAccountTreeDefault = new fetchModel<{}, any>({
		fnPromise: args => enterprise.getBackOfficeChartAccountTreeDefault(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackOfficeChartAccount = new fetchModel<
		{ id: string },
		BackOfficeChartAccountResponse
	>({
		fnPromise: args => enterprise.getBackOfficeChartAccount(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public setDefaultChartAccountToOrganizationBackOffice = new fetchModel<{}, any>({
		fnPromise: args => enterprise.setBackOfficeDefaultChartAccountToOrganization(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackofficeChartAccounts = new fetchModel<
		{
			operation?: BackOfficeOperationChartAccount | null;
			acceptTransactions?: boolean | null;
			isDre?: boolean | null;
			isCashflow?: boolean | null;
		},
		BackOfficeChartAccountResponse[]
	>({
		fnPromise: args => enterprise.getBackofficeChartAccounts(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public importBackofficeChartAccountXlsx = new fetchModel<
		{ file: ArrayBuffer },
		BackOfficeChartAccountResponse[]
	>({
		fnPromise: args => enterprise.importBackofficeChartAccountXlsx(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public exportBackofficeDefaultChartAccountXlsx = new fetchModel<{}, string>({
		fnPromise: args => enterprise.exportBackofficeDefaultChartAccountXlsx(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});
}
