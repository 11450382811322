import { merge } from "lodash-es";
import frJson from "./fr.json";
import { PURCHASE_INVOICE_FR_FR } from "#pages/place/purchase-invoice/translations/purchase-invoice-fr-fr";
import { IMPORT_FR_FR } from "#pages/place/purchase-invoice/import-invoice/translations/import-fr-fr";
import { CONCILIATION_FR_FR } from "#pages/backoffice/conciliation/translations/conciliation-fr-fr";
import { delivery } from "./place/delivery";

const fr = merge(frJson, {
	place: {
		purchaseInvoiceV2: PURCHASE_INVOICE_FR_FR,
		import: IMPORT_FR_FR,
		delivery,
	},
	backoffice: { conciliation: CONCILIATION_FR_FR },
});

export { fr };
