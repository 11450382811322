export const accountsPayableReceivable = {
	accounts: "Contas",
	addAccount: "Adicionar conta",
	exportExcel: "Exportar para excel",
	importExcel: "Importar para excel",
	print: "Imprimir",
	payable: "A pagar",
	receivable: "A receber",
	emptyText: "Nenhuma conta encontrada",
	isPaidYes: "Sim",
	isPaidNo: "Não",
	paymentStatus: {
		All: "Todos",
		Paid: "Pagos",
		NotPaid: "Não Pagos",
	},
	buttonActions: {
		markAsPaid: "Marcar como pago",
		unMarkAsPaid: "Desmarcar como pago",
		markAsReceived: "Marcar como recebido",
		unMarkAsReceived: "Desmarcar como recebido",
		viewDetails: "Ver detalhes",
	},
	status: {
		payabled: "Pago",
		recivabled: "Recebido",
		late: "Atrasado",
		conciliated: "Conciliado",
		payablePending: "A pagar",
		receivablePending: "A receber",
		payableToday: "Pagar hoje",
		receivableToday: "Receber hoje",
	},
	titleAccountPayable: "Contas a pagar",
	excelFileNamePayable: "contas_a_pagar",
	titleAccountReceivable: "Contas a receber",
	excelFileNameReceivable: "contas_a_receber",
	form: {
		newAccountPayable: "Nova conta a pagar",
		editAccountPayable: "Editar conta a pagar",
		newAccountReceivable: "Nova conta a receber",
		editAccountReceivable: "Editar conta a receber",
		informOfPayments: "Informações de pagamento",
		postingInformation: "Informações de lançamento",
		lblSupplier: "Fornecedor",
		lblClient: "Cliente",
		phSupplier: "Selecionar fornecedor",
		lblDocNumber: "Número do documento",
		phDocNumber: "Inserir número do documento",
		lblInvoiceDate: "Data de competência",
		phInvoiceDate: "Selecionar data de competência",
		lblPaymentStatus: "Status de pagamento",
		lblPaymentDate: "Data de pagamento",
		phPaymentDate: "Selecionar data de pagamento",
		lblDueDate: "Data de vencimento",
		phDueDate: "Selecionar data de vencimento",
		lblDescription: "Descrição",
		phDescription: "Inserir descrição",
		phValue: "Inserir valor",
		lblValue: "Valor",
		lblPaymentMethod: "Forma de pagamento",
		phPaymentMethod: "Selecionar forma de pagamento",
		lblAccountId: "Conta bancária",
		phAccountId: "Selecionar conta bancária",
		lblCostCenter: "Centro de custo",
		phCostCenter: "Selecionar centro de custo",
		lblChartAccounts: "Plano de contas",
		phChartAccounts: "Selecionar plano de contas",
		ttRequiredWhenFilled:
			"Ao preencher o campo de valor, o plano de contas se torna obrigatório.",
		lblInterest: "Juros por atraso",
		phInterest: "Selecionar juros por atraso",
		lblFine: "Multa por atraso",
		phFine: "Selecionar multa por atraso",
		lblDiscount: "Descontos",
		phDiscount: "Selecionar descontos",
		lblPaidValue: "Valor pago",
		phPaidValue: "Selecionar valor pago",
		phZeroValue: "0,00",
		swtIsPaid: "Pago",
		swtIsReceived: "Recebido",
		swtConciliated: "Conciliado",
		swtTotalValue: "Valor total:",
		recurringPayment: "Pagamento Recorrente",
		lblRecurringPaymentInterval: "Repetir a cada",
		lblRecurringPaymentTypeInterval: "Intervalo",
		lblRecurringPaymentTimes: "Por vez (vezes)",
		totalRepetition: "Total em ",
		recurringEnd: "Fim da recorrência:",
		observation: "Observação",
		attachment: "Anexo",
		lblObservation: "Inserir observação",
		lblAttachment: "Clique ou arraste para anexar um arquivo",
		lblFinancialFees: "Taxas administrativas",
		phFinancialFees: "Selecionar taxas administrativas",
		validations: {
			dueDate: "Insira a data de vencimento para continuar",
			value: "Insira o valor para continuar",
			chartAccounts: "Selecione o plano de contas para continuar",
		},
		modalDelete: {
			title: "Excluir conta",
			cancelText: "Cancelar",
			okText: "Excluir conta",
			message:
				"A exclusão de conta é uma ação definitiva. Deseja prosseguir com a exclusão?",
		},
	},
	columns: {
		docNumber: "Nº DO DOCUMENTO",
		supplier: "FORNECEDOR",
		client: "CLIENTE",
		chartOfAccount: "PLANO DE CONTAS",
		dueDate: "DATA DE VENCIMENTO",
		invoiceDate: "DATA DE COMPETÊNCIA",
		paymentDate: "DATA DE PAGAMENTO",
		value: "VALOR",
		situation: "SITUAÇÃO",
		actions: "AÇÕES",
	},
	labelFilter: "Clique para filtrar",
	selectSupplier: "Filtrar por fornecedor",
	lblDocNumber: "Filtrar por número do documento",
	phDocNumber: "Digite o número para filtrar",
	lblPaymentDate: "Filtrar por data de pagamento",
	lblPaymentStatus: "Filtrar por status de pagamento",
	optPaymentStatus1: "Todos",
	optPaymentStatus2: "Pagos",
	optPaymentStatus3: "Não Pagos",
	lblDueDate: "Filtrar por data de vencimento",
	lblInvoiceDate: "Filtrar por data de competência",
	tzOverdue: "Vencidos",
	tzmaturing: "Vence hoje",
	tzDue: "A vencer",
	tzPaid: "Pagos",
	tzTotal: "Total do período",
	attatchmentDescription:
		"Anexe comprovantes de pagamento ou outros documentos relacionados a essa conta para não perdê-los de vista. São permitidos até 4 documentos nos formatos PDF, OFX, XML, XLS, JPG e PNG com até 1.5MB.",
	attachFile: "Clique para anexar um arquivo",
	attachImportError: {
		existingFile: "Arquivo já importado",
		exceededSize: "Limite de tamanho excedido. O anexo não deve ser maior que 1.5MB",
	},
	attachModal: {
		title: "Anexos da conta",
		noAttachments: "Nenhum anexo encontrado no momento.",
		table: {
			columns: {
				attachedFiles: "Arquivos anexados",
			},
			successfullyDeletedFile: "Arquivo deletado",
			errorDeleted: "Não foi possível apagar o arquivo",
		},
	},
	attachedToChartAccountsModal: {
		linksPlural: "{{chartAccounts}} vínculos",
		linksSingular: "1 vínculo",
		seeLinks: "Ver links",
		title: "Vínculos com Plano de Contas",
		description: "Relação de cadastro com os quais esse item está vinculado",
	},
	modalPayment: {
		title: "Marcar como pago",
		description:
			"Selecione a data do pagamento e a conta bancária em que deseja registrar o pagamento",
		paymentDate: "Data de pagamento",
		paymentAccount: "Conta bancária",
		selectAccount: "Selecionar uma conta bancária",
		required: "Por favor selecione uma data de pagamento",
		deletedAccount: "Conta deletada",
		cancel: "Cancelar",
		confirm: "Confirmar",
		addAccount: "Adicionar conta",
		errors: {
			paymentDate: {
				required: "Data de pagamento é obrigatória",
				invalid: "Formato de data inválido",
			},
			paymentAccount: {
				required: "Conta de pagamento é obrigatória",
			},
		},
	},
};
