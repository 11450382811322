import { audits } from "./audits";
import { barReports } from "./bar-reports";
import { businessIntelligence } from "./business-intelligence/";
import { discountCategory } from "./discount-category";
import { emissionErrors } from "./emission-errors";
import { emited } from "./emited";
import { entrance } from "./entrance";
import { entranceBilling } from "./entrance-billing/";
import { events } from "./events/";
import { extract } from "./extract";
import { passwordCard } from "./password-card";
import { notEmitedProducts } from "./not-emited-products";
import { promotion } from "./promotion";
import { search } from "./search";
import { transactions } from "./transactions";
import { recharges } from "./recharges";
import { invoices } from "./invoices";
import { kardex } from "./kardex";
import { multivendor } from "./multivendor";
import { coala } from "./coala";
import { financialReport } from "./financial-report/";
import { fiscalReport } from "./fiscal-report";
import { guestList } from "./guest-list/";
import { pos } from "./pos";
import { precharge } from "./precharge";
import { products } from "./products";
import { productMenu } from "./product-menu";
import { promotions } from "./promotions";
import { settings } from "./settings";
import { tagGroups } from "./tag-groups";
import { storage } from "./storage/";
import { whatsUp } from "./whats-up";
import { importXml } from "./import-xml";
import { reserve } from "./reserve";
import { productDetail } from "./products/product-detail";
import { productArea } from "./products/product-area";
import { inventoryControl } from "./inventory-control";
import { prechargeReports } from "./precharge-reports";
import { contracts } from "./contracts";
import { rechargeManagement } from "./recharge-management";
import { transactionsReport } from "./transactions-report";
import { bonusCategories } from "./bonus-categories";
import { PURCHASE_INVOICE_PT_BR } from "#pages/place/purchase-invoice/translations/purchase-invoice-pt-br";
import { IMPORT_PT_BR } from "#pages/place/purchase-invoice/import-invoice/translations/import-pt-br";
import { delivery } from "./delivery";

export const placePT = {
	placeTitle: "Locais",
	placeWithoutEvent: "Locais sem evento há mais de um mês",
	placeWithOpenEvent: "Locais com evento em curso",
	placeWithoutEventInProgress: "Locais sem evento em curso",
	searchPlaceInputPlaceholder: "Buscar local",
	timezone: "Timezone",
	currency: "Moeda",
	subtitle: "Legenda",
	alphabeticalOrder: "Ordem alfabética",
	lastEventOrder: "Último evento criado",
	initialDefaultDateOrder: "Filtro padrão de datas",
	subtitles: {
		onGoingEvent: "Evento em curso",
		recentEvent: "Evento recente",
		longTimeWithoutEvent: "Longo tempo sem evento",
		waitingForApproval: "Aguardando Aprovação",
		noEvent: "Sem evento",
	},
	placelinks: {
		post: "Pós",
		pre: "Pré",
		noEvents: "Sem eventos",
		none: "Nenhum evento",
		openEvent: "Evento aberto",
	},
	placeTabs: {
		products: "Produtos",
		combos: "Combos",
		supplies: "Insumos",
		bars: "Bares",
		beer: "Chopeira",
		gng: "Grab and Go",
		cmv: "Eng. de cardápio",
		config: "Configurações de cardápios",
	},
	events,
	products,
	productMenu,
	businessIntelligence,
	entranceBilling,
	tagGroups,
	reserve,
	storage,
	financialReport,
	guestList,
	entrance,
	pos,
	promotions,
	whatsUp,
	audits,
	settings,
	precharge,
	barReports,
	extract,
	passwordCard,
	notEmitedProducts,
	promotion,
	search,
	transactions,
	invoices,
	kardex,
	multivendor,
	coala,
	discountCategory,
	emissionErrors,
	emited,
	fiscalReport,
	importXml,
	productDetail,
	productArea,
	inventoryControl,
	prechargeReports,
	contracts,
	rechargeManagement,
	recharges,
	transactionsReport,
	bonusCategories,
	purchaseInvoiceV2: PURCHASE_INVOICE_PT_BR,
	import: IMPORT_PT_BR,
	delivery,
};
