import { expiredSession } from "./expired-session";

export const noAuthEN = {
	expiredSession,
	login: {
		dashboard: "Dashboard",
	},
	errors: {
		required: "Required field",
		passwordMustBeSame: "Passwords must be the same",
		minLength: "Password must be at least {{count}} characters long",
	},
	actions: {
		send: "Send",
	},
	confirmMessages: {
		passwordUpdated: "Password successfully updated",
	},
	labels: {
		password: "Password",
		confirmPassword: "Confirm password",
	},
	recoveryPassword: "Password recovery",
};
