import { TabTagType } from "#resources/helpers/tabs";
import classNames from "classnames";
import * as React from "react";
import s from "./icon.scss";
import { ICON_NAMES } from "./constants";

interface Props extends React.SVGProps<SVGElement> {
	icon: IconName;
	className?: string;
	style?: React.CSSProperties;
	tagType?: TabTagType;
	rotate?: number;
}

export type IconName = typeof ICON_NAMES[number];

// debito técnico: move this to svgr and get icon types by icons file name

const MAX_ROTATE = 360;

const Icon = ({ className, icon, tagType, rotate, ...svgProps }: Props) => {
	const SvgComponent = React.lazy(() => import(`./imgs/${icon}.svg`));

	const rotationStyle =
		rotate && rotate <= MAX_ROTATE ? { transform: `rotate(${rotate}deg)` } : {};

	return (
		<React.Suspense fallback="-">
			<div className={classNames(s.icon, className)}>
				<SvgComponent data-icon={icon} style={rotationStyle} {...svgProps} />

				{tagType && (
					<div
						className={classNames({
							[s.notification]: true,
							[s.new]: tagType === "new",
							[s.comingSoon]: tagType === "comingSoon",
						})}
					/>
				)}
			</div>
		</React.Suspense>
	);
};

export default Icon;
