export const profitMarginReport = {
	seeMore: "Show More",
	pageTitle: "Cost and Profit Margin Report",
	empty: "No reports available",
	label: "Costs and profit margin",
	notAssociatedReport: "(Unassociated report)",
	export: "Export",
	print: "Print",
	selectPlaceholder: "Select here",
	excelFileName: "cost_and_profit_margin_report",
	successfullExportMsg: "Report exported successfully",
	period: "Period",
	header: {
		description:
			"This report provides an overview of the financial performance of the products, showing their revenue, quantity sold, sale price, costs, and profit margin.",
	},
	columns: {
		productName: "Product",
		category: "Category",
		quantity: "Quantity",
		value: "Average Value",
		soldAmount: "Total",
		invoicing: "Participation",
		averageSellingPrice: "Average Unit Price",
		totalCost: "Total Cost",
		costPercentage: "% Cost",
		profitMarginValue: "Profit",
		profitMarginPercentage: "% Profit",
	},
	indicators: {
		updatedAt: "Updated {{ date }}",
		profitMargin: {
			title: "Profit Margin",
		},
		totalBilling: {
			title: "Total Billing",
		},
		totalCost: {
			title: "Total Cost",
		},
		mostProfitMargin: {
			title: "Highest Profit Margin",
			seeMore: "Products with highest profit margin",
		},
		lessProfitMargin: {
			title: "Lowest Profit Margin",
			seeMore: "Products with lowest profit margin",
		},
	},
	flags: {
		red: "Among the 5 products with the lowest profit margin",
		green: "Among the 5 products with the highest profit margin",
	},
	filters: {
		period: "Filter by period",
		product: "Filter by product",
		category: "Filter by category",
		allProducts: "All products",
		allCategories: "All categories",
	},
};
